import omitDeep from "omit-deep-lodash";
import { v4 } from "uuid";
import { Notify } from "@digitallab/grid-common-components";

import {
  LOG_SHEET_BY_CLUSTER_ENTRY_REF_ID,
  ON_CREATE_DIGITAL_LAB_LOGBOOK_LOG_SHEET,
  ON_UPDATE_DIGITAL_LAB_LOGBOOK_LOG_SHEET,
  CREATE_DIGITAL_LAB_LOGBOOK_LOG_SHEET_CHANGE,
  CREATE_DIGITAL_LAB_LOGBOOK_LOG_SHEET_CLUSTER_CHANGE
} from "../../../../gql/logBooksapi";
import { getAllData, waitUntilSubscribeConditionTimes } from "../../../../utils/helpers/fetching";
import { omitOrReturn, omitOrReturnArray, parseTime } from "../../../../utils/helpers/text";
import { LogStatus } from "../../enums";
import { changeDateFormat } from "../../helpers";
import { getSubEquipmentStructure } from "../../run-logs/run-logs-form/CreateRunLogChange";
import { entryType } from "../../../../constants";

export const checkSubscribeCondition = (variables, dataProperty) => {
  return (data) => data[dataProperty].id === variables.logSheetEntryId;
};

const getUpdateSoftwareVersionOfPublishing = (theUpdateSoftwareVersion) => {
  if (theUpdateSoftwareVersion?.newValue) {
    return Boolean(theUpdateSoftwareVersion?.shouldBePublished);
  } else {
    return null;
  }
};

export const CreateLogSheetChange = async (logSheet, { client, isNew, equipmentDetail }) => {
  try {
    delete logSheet.gxpReady?.site;
    delete logSheet.systemStatus?.site;
    const actionDate =
      logSheet.actionDate instanceof Date ? changeDateFormat(logSheet.actionDate) : logSheet.actionDate;
    const variables = {
      ...logSheet,
      id: v4(),
      logSheetEntryId: isNew ? v4() : equipmentDetail.id,
      inventoryId: logSheet.inventoryId || null,
      modelVersion: "3",
      equipmentModel: logSheet.equipmentModel || equipmentDetail?.equipmentModel,
      materialNumber: logSheet.materialNumber || equipmentDetail?.materialNumber,
      logStatus: LogStatus.ACTIVE,
      description: logSheet.description || null,
      actionDate: actionDate || null,
      actionTime:
        parseTime(logSheet.actionTime, {
          timeFormat: "HH:mm:ss",
          parseFormat: "hh:mm a"
        }) || null,
      action: { key: logSheet.action, value: logSheet.action },
      logbookConfigurationDetails: omitOrReturn(logSheet.logbookConfigurationDetails),
      gxpReady: omitOrReturn(logSheet.gxpReady) || null,
      externalDocument: omitOrReturnArray(logSheet.externalDocument) || null,
      systemStatus: omitOrReturn(logSheet.systemStatus) || null,
      updatedSoftwareVersion: {
        ...omitOrReturn(logSheet.updatedSoftwareVersion),
        shouldBePublished: getUpdateSoftwareVersionOfPublishing(logSheet.updatedSoftwareVersion)
      }
    };

    const response = await client.mutate({
      mutation: CREATE_DIGITAL_LAB_LOGBOOK_LOG_SHEET_CHANGE,
      fetchPolicy: "no-cache",
      variables
    });

    if (isNew) {
      await waitUntilSubscribeConditionTimes(
        client,
        ON_CREATE_DIGITAL_LAB_LOGBOOK_LOG_SHEET,
        checkSubscribeCondition(variables, "onCreateDigitalLabLogbookLogSheet"),
        3
      );
    } else {
      await waitUntilSubscribeConditionTimes(
        client,
        ON_UPDATE_DIGITAL_LAB_LOGBOOK_LOG_SHEET,
        checkSubscribeCondition(variables, "onUpdateDigitalLabLogbookLogSheet"),
        3
      );
    }

    Notify({
      title: "",
      icon: "circle_confirm",
      type: "success",
      text: "Successfully saved action log!"
    });
    return response;
  } catch (error) {
    Notify({
      title: "",
      icon: "no",
      type: "warning",
      text: "Failed to save action log!"
    });

    return { error };
  }
};

export const CreateLogSheetChangeCluster = async (
  logSheet,
  { client, isNew, selectedEquipments, equipmentDetail, selectedOnlyParents = [] }
) => {
  try {
    const actionDate = logSheet.actionDate instanceof Date ?
      changeDateFormat(logSheet.actionDate) :
      logSheet.actionDate;
    const inputArray = generateInputVariable({
      selectedEquipments: [...selectedEquipments, ...selectedOnlyParents],
      logSheet,
      isNew,
      actionDate,
      equipmentDetail
    });

    const response = await client.mutate({
      mutation: CREATE_DIGITAL_LAB_LOGBOOK_LOG_SHEET_CLUSTER_CHANGE,
      fetchPolicy: "no-cache",
      variables: { input: omitDeep([...inputArray], "subEquipmentDetails") }
    });

    if (isNew) {
      await waitUntilSubscribeConditionTimes(
        client,
        ON_CREATE_DIGITAL_LAB_LOGBOOK_LOG_SHEET,
        checkSubscribeCondition(inputArray[0], "onCreateDigitalLabLogbookLogSheet"),
        3
      );
    } else {
      await waitUntilSubscribeConditionTimes(
        client,
        ON_UPDATE_DIGITAL_LAB_LOGBOOK_LOG_SHEET,
        checkSubscribeCondition(inputArray[0], "onUpdateDigitalLabLogbookLogSheet"),
        3
      );
    }
    if (response?.data?.createDigitalLabLogbookClusterLogSheetChange) {
      Notify({
        title: "",
        icon: "circle_confirm",
        type: "success",
        text: "Successfully saved action log!"
      });
      return response;
    }
  } catch (error) {
    Notify({
      title: "",
      icon: "no",
      type: "warning",
      text: "Failed to save action log!"
    });
    return { error };
  }
};

const getActionLogDetailsStructure = ({ logSheet, isCluster = false, equipmentDetail, actionDate, isNew = false }) => {
  const instrumentDetails = {
    instrumentGxPStatus: equipmentDetail?.instrumentGxPStatus || null,
    equipmentNickName: equipmentDetail?.equipmentNickName || null,
    responsiblePerson: equipmentDetail?.responsiblePerson || null,
    manufacturer: equipmentDetail?.manufacturer || null,
    softwareVersion: equipmentDetail?.softwareVersion || null,
    location: equipmentDetail?.location || null,
    responsibleProxy: equipmentDetail?.responsibleProxy || null
  };

  if (isCluster) {
    instrumentDetails.configurationBaseline = equipmentDetail?.configurationBaseline || null;
  }
  if (isCluster) {
    delete logSheet?.gxpReady?.site;
    delete logSheet?.systemStatus?.site;
  } else {
    delete equipmentDetail?.gxpReady?.site;
    delete equipmentDetail?.systemStatus?.site;
  }
  let actionLogObj = {
    action: { key: logSheet?.action, value: logSheet?.action },
    actionDate: actionDate || null,
    actionTime:
      parseTime(logSheet.actionTime, {
        timeFormat: "HH:mm:ss",
        parseFormat: "hh:mm a"
      }) || null,
    approverId: logSheet?.approverId || null,
    approverUserName: logSheet?.approverUserName || "",
    description: logSheet?.description || null,
    ecrNumber: logSheet?.ecrNumber,
    equipmentId: isCluster ? logSheet?.equipmentId : equipmentDetail?.equipmentId || null,
    editReason: logSheet?.editReason || "",
    editComment: logSheet?.editComment || "",
    gxpReady: isCluster ? omitOrReturn(logSheet?.gxpReady) : omitOrReturn(equipmentDetail?.gxpReady) || null,
    gxpReadyCluster: isCluster
      ? omitOrReturn(logSheet?.gxpReadyCluster)
      : omitOrReturn(equipmentDetail?.gxpReady) || null,
    id: v4(),
    instrumentDetails,
    equipmentModel: isCluster ? logSheet?.equipmentModel : equipmentDetail?.equipmentModel || null,
    inventoryId: isCluster ? logSheet?.inventoryId : equipmentDetail?.inventoryId || null,
    logSheetEntryId: isNew ? v4() : isCluster ? equipmentDetail?.id : equipmentDetail?.logSheetEntryId || v4(),
    logStatus: LogStatus.ACTIVE,
    materialNumber: isCluster ? logSheet?.materialNumber : equipmentDetail?.materialNumber || "",
    modelVersion: "3",
    operatorId: logSheet?.operatorId,
    operatorUserName: logSheet?.operatorUserName,
    serialNumber: isCluster ? logSheet?.serialNumber : equipmentDetail?.serialNumber || "",
    isClusterQualificationChanged:
      equipmentDetail?.addInfo?.currentGxPStatusFromLogs && equipmentDetail?.addInfo?.currentSystemStatusFromLogs
        ? !logSheet?.isClusterQualificationChanged
        : true,
    site: logSheet?.site || null,
    systemStatus: isCluster
      ? omitOrReturn(logSheet?.systemStatus)
      : omitOrReturn(equipmentDetail?.systemStatus) || null,
    systemStatusCluster: isCluster
      ? omitOrReturn(logSheet?.systemStatusCluster)
      : omitOrReturn(equipmentDetail?.systemStatus) || null,
    updatedSoftwareVersion: omitOrReturn(logSheet?.updatedSoftwareVersion),
    externalDocument: omitOrReturnArray(logSheet?.externalDocument),
    attachments: omitOrReturnArray(logSheet?.attachments)
  };

  if (equipmentDetail?.entryType !== entryType?.cluster) {
    delete actionLogObj.gxpReadyCluster;
    delete actionLogObj.systemStatusCluster;
  }

  return actionLogObj;
};

const generateInputVariable = ({ selectedEquipments, logSheet, isNew, actionDate, equipmentDetail }) => {
  let variables = [];
  let clusterObj = getActionLogDetailsStructure({
    equipmentDetail,
    logSheet,
    isNew,
    actionDate,
    isCluster: true
  });
  clusterObj = {
    ...clusterObj,
    subEquipment: getSubEquipmentStructure(selectedEquipments)
  };
  variables.push(clusterObj);
  selectedEquipments.forEach((subEquipment) => {
    let subEquipmentObj = {
      ...getActionLogDetailsStructure({
        equipmentDetail: subEquipment,
        logSheet,
        isNew,
        actionDate
      })
    };

    delete subEquipmentObj.id;
    delete subEquipmentObj.isClusterQualificationChanged;

    subEquipmentObj = {
      ...subEquipmentObj,
      clusterEntryRefId: clusterObj?.logSheetEntryId
    };
    variables.push(subEquipmentObj);
  });

  return variables;
};

export const getActionLogSubEquipments = async ({ client, id }) => {
  const subEquipmentData = await getAllData({
    client,
    query: LOG_SHEET_BY_CLUSTER_ENTRY_REF_ID,
    variables: {
      limit: 1000,
      clusterEntryRefId: id
    },
    dataPath: ["data", "logSheetByClusterEntryRefId"],
    drillData: true
  });
  return subEquipmentData?.items;
};
