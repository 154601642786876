import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { DLabEquipmentList as CommonAgGrid } from "@digitallab/grid-common-components";
import { CloudSearchConfig } from "../../components/shared/CloudSearchConfig";
import MyEquipmentMenu from "./MyEquipmentMenu";
import {
  agGridAutoGroupfieldName,
  allTableColumn,
  DEFAULT_COLUMNS,
  DEFAULT_FILTER,
  DEFAULT_PAGE_SIZE,
  DUMMY_ROW,
  entryType,
  listOfFieldAcceptOnlyInteger,
  listOfFieldsIgnoredForSearch,
  listOfFieldsSortable,
  listOfNumberFilter,
  literalFields
} from "../../constants";
import { camelCase, filter, find, mapKeys, mapValues, sortBy, uniq } from "lodash";
import { convertStrToObjColumns, convertToCamelCase, convertToSnakeCase, getEnv } from "../../utils/helpers/text";
import { getContainerHeight, getSubEquipments } from "../../utils/helpers/fetching";
import { getEquipDetailInfo } from "../log-book/LoadLogBooksInfo";
import {
  getColumnObj,
  onCreatefilter,
  onCreateSelectColumn,
  onDeleteSaveConfigurations,
  onUpdateFilter,
  onUpdateSelectColumn,
  saveLastPageSize
} from "./EquipmentHelper";
import { filterKeyMapping, TOOL_PANELS } from "../../components/DLabGrid/dLabGridConstant";
import DATA_MODEL_TABLE from "../../utils/constants/dataModelTable";
import { useSelector } from "react-redux";
import { withApollo } from "react-apollo";
import {
  CREATE_USER_DISPLAY_COLUMN,
  CREATE_USER_FILTER,
  DELETE_USER_DISPLAY_COLUMN,
  DELETE_USER_FILTER,
  UPDATE_DIGITAL_LAB_LOGBOOK_USER_PROFILE_PERSONAL_FIELDS,
  UPDATE_USER_DISPLAY_COLUMN,
  UPDATE_USER_FILTER
} from "../../gql/logBooksapi";
import { OwcIconButton, OwcTypography } from "@one/react";
import EquipmentActionPage from "./EquipmentActionPage";
import { CoverSheetMainPageContext } from "../cover-sheet/cover-sheet-main-page-context/context";

const currentEnv = getEnv();
const EquipmentMainPage = ({
  masterDataFilters,
  myLabFilter,
  lastFavFilter,
  setLastFavFilter,
  userFilters,
  setUserFilters,
  userDisplayColumn,
  setUserDisplayColumn,
  lastDisplayColumns,
  setLastDisplayColumns,
  lastPageSize,
  setLastPageSize,
  client
}) => {
  const remainingHeight = 195; //  header + footer + pageHeader
  const [dataModel, setDataModel] = useState({});
  const [additionalFields, setAdditionalFields] = useState([]);
  const [attributes, setAttributes] = useState({});
  const [defaultShowColumns, setDefaultShowColumns] = useState({
    ...DEFAULT_COLUMNS
  });
  const [showMenu, setShowMenu] = useState(false);
  const [containerHeight, setContainerHeight] = useState(getContainerHeight(currentEnv, remainingHeight));
  window.addEventListener("resize", function () {
    setContainerHeight(getContainerHeight(currentEnv, remainingHeight));
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [anchorId, setAnchorId] = useState("");
  const storeUser = useSelector((store) => store.user);
  const { logBookEquipment, loadLogBookEquipment, logType } = useContext(CoverSheetMainPageContext);
  const localUserFilters = useRef(userFilters);
  const systemStatuses = useSelector((store) => store.runLogsForm.systemStatuses);
  const gxpReadys = useSelector((store) => store.runLogsForm.gxpReadys);
  const autoGroupColField = "equipment_model";
  const [equipmentDetails, setEquipmentDetails] = useState({});

  useEffect(() => {
    setAttributes(() => attr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerHeight]);
  useEffect(() => {
    localUserFilters.current = userFilters;
  }, [userFilters]);

  useEffect(() => {
    const unorderedHiddenColumn = [];
    const orderedShowColumns = sortBy(
      convertStrToObjColumns(lastDisplayColumns)?.displayDefinition?.showColumns,
      ["order"],
      ["asc"]
    );

    allTableColumn()?.forEach((value) => {
      const obj = find(orderedShowColumns, {
        key: value?.key
      });
      if (!obj) {
        unorderedHiddenColumn.push(value);
      }
    });

    const redefinedColumns = [...orderedShowColumns, ...unorderedHiddenColumn]?.map((value) => {
      return getColumnObj(value, masterDataFilters, convertToCamelCase(autoGroupColField));
    });

    const defaultShowCols = filter(allTableColumn(), { show: true });
    setDefaultShowColumns(() => {
      return defaultShowCols?.map((value) => {
        return getColumnObj(value, masterDataFilters, convertToCamelCase(autoGroupColField));
      });
    });

    const aditionColumns = [
      {
        field: "actions",
        headerName: "Actions",
        maxWidth: 50,
        filter: false,
        pinned: "right",
        lockVisible: true,
        lockPosition: "right",
        cellClass: "action-render",
        cellRenderer: actionRender
      }
    ];
    setAdditionalFields(() => [...aditionColumns]);
    setDataModel([...redefinedColumns]);
    // eslint-disable-next-line
  }, [masterDataFilters]);

  let attr = {
    height: containerHeight,
    rowModelType: "serverSide",
    rowQuickSearch: false,
    treeData: true,
    suppressPaginationPanel: true,
    pagination: true,
    animateRows: true,
    rowSelection: "multiple",
    rowMultiSelectWithClick: true,
    rowExport: false,
    masterDetail: true,
    isGroupOpenByDefault: false,
    defaultToolPanel: "filters",
    hiddenByDefault: true,
    serverSideInfiniteScroll: false,
    serverSideSortOnServer: true,
    suppressRowClickSelection: true
  };

  const requestServer = (cloudSearchConfigOption) => {
    setShowMenu(false);
    return CloudSearchConfig(cloudSearchConfigOption);
  };
  const isServerSideGroup = useCallback((dataItem) => {
    // indicate if node is a group

    if (dataItem?.entry_type && dataItem?.entry_type?.includes(entryType?.cluster)) {
      return dataItem?.id;
    }

    return "";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getServerSideGroupKey = useCallback((dataItem) => {
    // specify which group key to use

    if (dataItem?.entry_type && dataItem?.entry_type?.includes(entryType?.cluster)) {
      return dataItem?.id;
    }

    return "";
  }, []);
  const autoGroupColumnDef = useMemo(() => {
    return {
      headerName: "Model",
      field: autoGroupColField,
      sortable: true,
      pinned: "left",
      lockPinned: true,
      filter: "agSetColumnFilter",
      lockVisible: true,
      filterParams: {
        buttons: ["reset"],
        defaultToNothingSelected: true,
        values: (params) => {
          const values = masterDataFilters[DATA_MODEL_TABLE?.equipmentModel?.key] || [];
          params.success(values);
        }
      },
      cellStyle: (params) => {
        return params?.node?.level > 0 &&
          (params?.data?.entry_type[0] === entryType?.cluster ||
            params?.data?.entry_type[0] === entryType?.clusterSubequipment)
          ? {
              paddingLeft: 20,
              border: "none !important",
              backgroundColor: "#F5F5F2",
              backgroundImage: "conic-gradient(at 7% 90%, #FFFFFF 180deg, #F5F5F2 180deg)"
            }
          : {};
      },
      cellRendererParams: {
        innerRenderer: (params) => {
          return params?.data?.equipment_model[0];
        }
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handlePromises = async ({ promises, selectedEquipment = null, equipmentData }) => {
    const updatedEquipmentData = await getSubEquipments({
      promises,
      selectedEquipment,
      equipmentData,
      logType,
      gxpReadys,
      systemStatuses,
      isEditMode: false
    });
    if (updatedEquipmentData) {
      loadLogBookEquipment(updatedEquipmentData?.equipmentData);
    }
  };
  const actionRender = (params) => {
    return params?.data?.id[0] === DUMMY_ROW ? (
      ""
    ) : (
      <OwcIconButton
        flat
        type="outlined"
        icon="more_vertical"
        onClick={async () => {
          setAnchorId(params?.data?.id[0]);
          let promises = [];
          let clusterSubEquipmentPromises = [];
          let cloudSeachPromiesClusterSub = [];
          let equipDetails = await getEquipDetailInfo(params?.data?.id[0], client);
          let equipmentData = { ...equipDetails };
          equipmentData.equipSystemStatus = equipmentData?.systemStatus;
          equipmentData.subEquipment = [];
          const clusterIds = [];
          const result = await CloudSearchConfig({
            sortBy: "position_in_cluster",
            searchValue: `cluster_id:${equipmentData?.inventoryId} AND site_name:${storeUser.site}`,
            start: 0,
            size: 1000,
            sort: "asc"
          });
          result?.data?.forEach((subEquip) => {
            if (subEquip.entry_type[0] === entryType?.cluster) {
              clusterIds.push(subEquip.id[0]);
            }
            promises?.push(getEquipDetailInfo(subEquip.id[0], client));
          });
          if (clusterIds?.length > 0) {
            clusterIds.forEach((clusterId) => {
              const clusterSubEquipment = CloudSearchConfig({
                sortBy: "position_in_cluster",
                searchValue: `cluster_id:${clusterId} AND site_name:${storeUser.site}`,
                start: 0,
                size: 1000,
                sort: "asc"
              });
              cloudSeachPromiesClusterSub.push(clusterSubEquipment);
            });

            const allCloudSearchPromised = Promise.all(cloudSeachPromiesClusterSub);
            allCloudSearchPromised?.then((clusterSubEquipmentRespose) => {
              clusterSubEquipmentRespose?.forEach((subEquip) => {
                subEquip?.data?.forEach((equip) => {
                  clusterSubEquipmentPromises?.push(getEquipDetailInfo(equip.id[0], client));
                });
              });
              handlePromises({
                promises: [...promises, ...clusterSubEquipmentPromises],
                equipmentData
              });
            });
          } else {
            handlePromises({
              promises: [...promises],
              equipmentData
            });
          }
          setEquipmentDetails(
            mapValues(
              mapKeys(params.data, (_, key) => camelCase(key)),
              (value) => value.join(",")
            )
          );
          setShowMenu(true);
        }}
        id={params?.data?.id[0]}
      />
    );
  };
  const onSave = async ({
    currentState,
    chipName = "",
    type = "update",
    isAgGridObj = false,
    pannel = "",
    isSaveConfigUpdated = false,
    updateRedux = true
  }) => {
    let result = null;
    if (pannel === TOOL_PANELS?.filters) {
      let obj = {};
      if (isAgGridObj) {
        for (const key in DEFAULT_FILTER.filterDefinition) {
          if (currentState[convertToSnakeCase(filterKeyMapping[key] || key)] === undefined) {
            obj[key] = DEFAULT_FILTER.filterDefinition[key];
          } else if (currentState[convertToSnakeCase(filterKeyMapping[key] || key)]?.filterType === "set") {
            let values = currentState[convertToSnakeCase(filterKeyMapping[key] || key)]?.values;
            if (
              filterKeyMapping[key] === DATA_MODEL_TABLE?.equipmentModel?.key ||
              key === DATA_MODEL_TABLE?.equipmentModel?.key
            ) {
              if (Object?.keys(currentState)?.includes(agGridAutoGroupfieldName)) {
                values = uniq([...values, ...currentState[agGridAutoGroupfieldName]?.values]);
              }
            }
            obj[key] = values;
          } else {
            obj[key] = currentState[convertToSnakeCase(filterKeyMapping[key] || key)];
          }
        }
      } else {
        obj = {
          ...currentState
        };
      }

      const lastSavedFilter = {
        filterDefinition: obj,
        filterName: chipName
      };
      if (type === "create") {
        lastSavedFilter.userID = storeUser?.id;
        lastSavedFilter.chipName = chipName;
        result = await onCreatefilter({
          id: storeUser?.id,
          email: storeUser?.email,
          userFilters: localUserFilters.current,
          lastFavFilter: lastSavedFilter,
          client,
          query: CREATE_USER_FILTER,
          userProfileQuery: UPDATE_DIGITAL_LAB_LOGBOOK_USER_PROFILE_PERSONAL_FIELDS,
          updateUserFilters: setUserFilters,
          updateLastFavFilter: setLastFavFilter
        });
      } else {
        result = await onUpdateFilter({
          lastFavFilter: lastSavedFilter,
          isSaveConfigUpdated: isSaveConfigUpdated,
          updateRedux,
          id: storeUser?.id,
          email: storeUser?.email,
          client,
          query: UPDATE_USER_FILTER,
          userProfileQuery: UPDATE_DIGITAL_LAB_LOGBOOK_USER_PROFILE_PERSONAL_FIELDS,
          updateLastFavFilter: setLastFavFilter
        });
      }
      return { savedObj: lastSavedFilter, result };
    } else if (pannel === TOOL_PANELS?.columns) {
      const savedColumns = [...currentState];
      const updatedSavedColumns = savedColumns.map((column, index) => {
        const dataModelKey = convertToCamelCase(column?.colId);
        return {
          key: dataModelKey,
          val: DATA_MODEL_TABLE[ dataModelKey ]?.value || "ignore",
          order: index,
          show: DATA_MODEL_TABLE[ dataModelKey ]?.value?.length ? !column?.hide : "ignore",
          sortStatus: listOfFieldsSortable.includes(DATA_MODEL_TABLE[ dataModelKey ]?.key)
        };
      });
      const showColumns = filter(updatedSavedColumns, { show: true });
      const hideColumns = filter(updatedSavedColumns, { show: false });
      let savecolumnObj = {
        displayName: chipName,
        displayDefinition: {
          showColumns,
          hideColumns
        }
      };
      if (type === "create") {
        savecolumnObj.userID = storeUser?.id;
        savecolumnObj.chipName = chipName;
        result = await onCreateSelectColumn({
          id: storeUser?.id,
          email: storeUser?.email,
          userDisplayList: userDisplayColumn,
          unParsedData: savecolumnObj,
          client,
          query: CREATE_USER_DISPLAY_COLUMN,
          userProfileQuery: UPDATE_DIGITAL_LAB_LOGBOOK_USER_PROFILE_PERSONAL_FIELDS,
          updateUserDisplayColumns: setUserDisplayColumn,
          updateLastDisplayColumn: setLastDisplayColumns
        });
      } else {
        result = await onUpdateSelectColumn({
          data: JSON.stringify(savecolumnObj),
          isSaveConfigUpdated,
          updateRedux,
          id: storeUser?.id,
          email: storeUser?.email,
          client,
          userProfileQuery: UPDATE_DIGITAL_LAB_LOGBOOK_USER_PROFILE_PERSONAL_FIELDS,
          query: UPDATE_USER_DISPLAY_COLUMN,
          userDisplayList: userDisplayColumn,
          updateUserDisplayColumns: setUserDisplayColumn,
          updateLastDisplayColumn: setLastDisplayColumns
        });
      }
      return { savedObj: savecolumnObj, result };
    }
  };

  const onChipDelete = async (chipToDelete, lastSavedChip, panel, userFilters) => {
    return await onDeleteSaveConfigurations({
      userID: storeUser?.id,
      chipToDelete,
      panel,
      lastSavedChip,
      client,
      email: storeUser?.email,
      deleteFilterQuery: DELETE_USER_FILTER,
      deleteColumnQuery: DELETE_USER_DISPLAY_COLUMN,
      userProfileQuery: UPDATE_DIGITAL_LAB_LOGBOOK_USER_PROFILE_PERSONAL_FIELDS,
      userFilters,
      userDisplayList: userDisplayColumn,
      updateUserFilters: setUserFilters,
      updateUserDisplayColumns: setUserDisplayColumn,
      updateLastFavFilter: setLastFavFilter,
      updateLastDisplayColumn: setLastDisplayColumns
    });
  };

  const onResetAll = async () => {
    await onUpdateFilter({
      lastFavFilter: DEFAULT_FILTER,
      updateRedux: true,
      id: storeUser?.id,
      email: storeUser?.email,
      client,
      query: UPDATE_USER_FILTER,
      userProfileQuery: UPDATE_DIGITAL_LAB_LOGBOOK_USER_PROFILE_PERSONAL_FIELDS,
      updateLastFavFilter: setLastFavFilter
    });
    await onUpdateSelectColumn({
      data: JSON.stringify(DEFAULT_COLUMNS),
      updateRedux: true,
      id: storeUser?.id,
      email: storeUser?.email,
      client,
      userProfileQuery: UPDATE_DIGITAL_LAB_LOGBOOK_USER_PROFILE_PERSONAL_FIELDS,
      query: UPDATE_USER_DISPLAY_COLUMN,
      userDisplayList: userDisplayColumn,
      updateUserDisplayColumns: setUserDisplayColumn,
      updateLastDisplayColumn: setLastDisplayColumns
    });
  };
  const onRowsPerChange = async (rowPerPage) => {
    await saveLastPageSize({
      lastPageSize: rowPerPage,
      query: UPDATE_DIGITAL_LAB_LOGBOOK_USER_PROFILE_PERSONAL_FIELDS,
      updateLastPageSize: setLastPageSize,
      id: storeUser?.id,
      email: storeUser?.email,
      client
    });
  };

  return (
    <>
      <div>
        <OwcTypography variant="title5" style={{ paddingLeft: "16px" }}>
          Equipment list
        </OwcTypography>
        {Object?.keys(attributes)?.length > 0 && (
          <CommonAgGrid
            autoGroupColumnDefObj={autoGroupColumnDef}
            columnData={dataModel}
            masterDataFilters={masterDataFilters}
            additionalFields={additionalFields}
            userFilters={userFilters}
            myLabFilter={myLabFilter}
            lastFavFilter={lastFavFilter}
            lastDisplayColumns={lastDisplayColumns ?? DEFAULT_COLUMNS}
            userDisplays={userDisplayColumn}
            systemDisplays={[]}
            onSave={onSave}
            onChipDelete={onChipDelete}
            defaultFilterObj={DEFAULT_FILTER}
            defaultShowColumns={defaultShowColumns}
            listOfFieldsIgnoredForSearch={listOfFieldsIgnoredForSearch}
            listOfNumberFilter={listOfNumberFilter}
            listOfFieldAcceptOnlyInteger={listOfFieldAcceptOnlyInteger}
            isServerSideGroupObj={isServerSideGroup}
            getServerSideGroupKeyObj={getServerSideGroupKey}
            selectedEquipment={[]}
            onResetAll={onResetAll}
            showSaveConfiguration={true}
            limit={lastPageSize ?? DEFAULT_PAGE_SIZE}
            onRowsPerChange={onRowsPerChange}
            requestServer={requestServer}
            siteName={storeUser?.site}
            literalFields={literalFields}
            {...attributes}
          ></CommonAgGrid>
        )}
      </div>
      {logBookEquipment && (
        <>
          <EquipmentActionPage
            setOpenDialog={setOpenDialog}
            openDialog={openDialog}
            logType={logType}
            equipmentDetail={logBookEquipment}
          />
        </>
      )}
      {showMenu && (
        <MyEquipmentMenu
          anchorId={anchorId}
          setShowMenu={setShowMenu}
          logBookEquipment={logBookEquipment}
          equipmentDetails={equipmentDetails}
        />
      )}
    </>
  );
};

// export default EquipmentMainPage;
export default withApollo(EquipmentMainPage);
