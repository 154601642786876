import { useFormikContext } from "formik";
import { formStates, formTypes } from "../../../../constants";
import { Mainbox, TitleAdjusted } from "../../log-book-item-form-dialog/FormsStyled";
import { useSelector } from "react-redux";
import { useContext, useEffect, useState } from "react";
import LOGBOOK_LABEL from "../../../../utils/constants/logbookLabel";
import ACTION_LOGS_DATA_MODEL from "../../../../utils/constants/actionLogsDataModel";
import { ActionTypeSelect, THEME } from "@digitallab/grid-common-components";
import { OwcBanner } from "@one/react";
import RunTimeInput from "../../run-logs/run-logs-form/run-data-inputs/RunTimeInput";
import { CoverSheetMainPageContext } from "../../../cover-sheet/cover-sheet-main-page-context/context";
import FileList from "./FileList";
import { LinksList } from "./LinksList";
import { GET_LIST_OF_ACTION_TYPES } from "../../../../gql/logBooksapi";
import { ActionDateInput } from "./ActionDateInput";
import { OperatorUserNameInput } from "./OperatorUserNameInput";
import { EcrNumberInput } from "./EcrNumberInput";
import { QualificationStatusSelectWithSwitch } from "./QualificationStatusSelectWithSwitch";
import { SystemStatusSelectWithSwitch } from "./SystemStatusSelectWithSwitch";
import { DescriptionTestArea } from "./DescriptionTestArea";
import { SoftwareInputWithSwitch } from "./SoftwareInputWithSwitch";
import { ConfigurationDetailsWithSwitch } from "./ConfigurationDetailsWithSwitch";
import { getAppSyncBookingClient } from "../../../../booking_client";
import { changeDateFormat } from "../../helpers";

const attachmentSites = process.env.REACT_APP_ATTACHMENT_SITES || [];

const ActionDataInputsBox = ({ isPropagationDisabled, numberOfSelectedEquipment }) => {
  const formik = useFormikContext();
  const [actionTime, setActionTime] = useState(null);
  const [actionTypes, setActionTypes] = useState([]);
  const [actionTypesError, setActionTypesError] = useState();

  const { formState } = useContext(CoverSheetMainPageContext);
  const editMode = formState === formStates?.EDITABLE_FORM;
  const storeUserSite = useSelector((store) => store.user?.site);

  useEffect(() => {
    const getActionTypes = async () => {
      try {
        const result = await getAppSyncBookingClient().query({
          query: GET_LIST_OF_ACTION_TYPES,
          fetchPolicy: "no-cache"
        });
        setActionTypes(result?.data?.__type?.enumValues?.map((val) => val?.name) || []);
      } catch (err) {
        console.error(err.message);
        setActionTypesError(err.message);
      }
    };

    getActionTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let tempActionTime;
    if (!editMode) {
      tempActionTime = formik.values.actionTime
        ? new Date(changeDateFormat(new Date()) + " " + formik.values.actionTime)
        : null;
    } else {
      tempActionTime = formik.values.actionTime
        ? new Date(changeDateFormat(new Date()) + " " + formik.values.actionTime)
        : null;

      formik.setFieldValue("updatedSoftwareVersion.shouldBePublished", false, true);
    }
    setActionTime(tempActionTime);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.actionTime]);

  return (
    <Mainbox>
      <TitleAdjusted data-testid="action-data-title-1">
        {LOGBOOK_LABEL.action_data}
      </TitleAdjusted>
      <ActionDateInput formik={formik} />
      <RunTimeInput
        property={ACTION_LOGS_DATA_MODEL.actionTime.key}
        label={ACTION_LOGS_DATA_MODEL.actionTime.value}
        required={true}
        testid="action-start-time"
        timeObj={actionTime}
        setTimeObj={setActionTime}
        maxTime={new Date().getTime()}
        logType={formTypes?.ACTION_LOG}
      />
      <ActionTypeSelect formik={formik} error={actionTypesError} name="action" actionTypes={actionTypes} />
      <OperatorUserNameInput formik={formik} />
      <EcrNumberInput formik={formik} />

      <div style={{ gridColumn: "span 3" }}>
        <DescriptionTestArea formik={formik} />
      </div>

      <TitleAdjusted data-testid="action-data-title-2">
        External document and file links
      </TitleAdjusted>
      <div style={{ display: "flex", flexDirection: "column", gridColumn: "1/5" }}>
        <div style={{ display: "flex", gap: "8px" }}>
          <LinksList />
          {attachmentSites.includes(storeUserSite) ? <FileList /> : null}
        </div>
      </div>


      <TitleAdjusted data-testid="action-data-title-3">
        Equipment relevant attributes (potentially propagated to repository)
      </TitleAdjusted>
      {
        isPropagationDisabled &&
        <OwcBanner
          type="info"
          style={{
            top: 0,
            marginBottom: THEME[ "one-spacer-12" ],
            gridColumn: "span 4",
            boxShadow: `${THEME[ "one-color-background-warning-1" ]} 0 0 0 2px`,
          }}
        >
          Propagation to Equipment Repository is not available. You have selected at least 1 equipment that
          requires additional review. Contact the Equipment Responsible Person if you want to provide update to
          Equipment Repository.
        </OwcBanner>
      }

      <div style={{
        display: "grid",
        gridGap: "20px 12px",
        gridColumn: "span 2",
        gridTemplateColumns: "repeat(2, 1fr)"
      }}>
        <div style={{ gridColumn: "span 1" }}>
          <QualificationStatusSelectWithSwitch
            formik={formik}
            editMode={editMode}
            isPropagationDisabled={isPropagationDisabled}
            numberOfSelectedEquipment={numberOfSelectedEquipment}
          />
        </div>
        <div style={{ gridColumn: "span 1" }}>
          <SystemStatusSelectWithSwitch
            formik={formik}
            editMode={editMode}
            isPropagationDisabled={isPropagationDisabled}
            numberOfSelectedEquipment={numberOfSelectedEquipment}
          />
        </div>
        <div style={{ gridColumn: "span 2" }}>
          <SoftwareInputWithSwitch
            formik={formik}
            isPropagationDisabled={isPropagationDisabled}
            editMode={editMode}
          />
        </div>
      </div>
      <div style={{ gridColumn: "span 2" }}>
        <ConfigurationDetailsWithSwitch
          formik={formik}
          isPropagationDisabled={isPropagationDisabled}
          editMode={editMode}
        />
      </div>
    </Mainbox>
  );
};

export default ActionDataInputsBox;
