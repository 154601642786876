import * as yup from "yup";
import DATA_MODEL_TABLE from "../utils/constants/dataModelTable";
import { format, parseISO, isBefore } from "date-fns";

export const actionLogValidationSchema = () =>
  yup.object({
    actionDate: yup.date().typeError("Enter action date").required("Enter correct action date").nullable(),
    actionTime: yup
      .string()
      .typeError("Enter action Time")
      .required("Enter correct action Time")
      .test("maxtimeValidation", "Time should not be after maximal time", (value, obj) => {
        let actionDateObj = obj?.parent?.actionDate;
        if (typeof actionDateObj === "string") {
          actionDateObj = new Date(actionDateObj);
        }
        const valueWithDate = parseISO(`${ format(actionDateObj, "yyyy-MM-dd") }T${ value }`);
        return isBefore(valueWithDate, new Date());
      }),
    action: yup.string()
      .typeError("Enter action type")
      .required("Enter action type"),
    externalDocument: yup
      .array(
        yup.object({
          link: yup.string().typeError("Enter valid URL"),
          name: yup.string().typeError("Enter Description")
        })
      )
      .nullable(),
    ecrNumber: yup.string().typeError("Enter ECR number").nullable(),
    description: yup.string().typeError("Enter description").required("Enter description"),
    gxpReady: yup
      .object({
        shouldBePublished: yup
          .boolean()
          .required(),
        newValue: yup
          .object({
            key: yup
              .string(),
            value: yup
              .string()
          })
          .required(`Enter ${ DATA_MODEL_TABLE?.gxpReady?.value }`)
      }),
    systemStatus: yup
      .object({
        shouldBePublished: yup
          .boolean()
          .required(),
        newValue: yup
          .object({
            key: yup
              .string(),
            value: yup
              .string(),
          })
          .required(`Enter ${ DATA_MODEL_TABLE?.systemStatus?.value }`)
      }),
    updatedSoftwareVersion: yup
      .object()
      .when(
        "action",
        {
          is: "OTHER_SOFTWARE_CHANGE",
          then: yup.object({
            shouldBePublished: yup
              .boolean()
              .required(),
            newValue: yup
              .string()
          }),
        }),
    logbookConfigurationDetails: yup
      .object({
        shouldBePublished: yup
          .boolean()
          .required(),
        newValue: yup
          .string()
      })
  });
