import { OwcCard, OwcIcon, OwcTypography } from "@one/react";
import { useContext, useEffect, useMemo } from "react";
import { compose } from "redux";
import { withApollo } from "react-apollo";
import { useFormikContext } from "formik";
import useDialog from "../../../../utils/hooks/useDialog";
import ConfirmDialogFiles from "../../../../components/shared/ConfirmDialogFiles";
import BinIcon from "../../../../icons/BinIcon";
import DownloadIcon from "../../../../icons/DownloadIcon";
import FileIcon from "../../../../icons/FileIcon";
import { getFile } from "./downloadFile";

import { ItemDialogFormContext } from "../../log-book-item-form-dialog/item-context/context";
import { LOGBOOK_ATTACHMENTS } from "../../../../constants";

function FileList() {
  const formik = useFormikContext();
  const { openDialog, ...dialogRest } = useDialog();
  const { setFiles, filesData } = useContext(ItemDialogFormContext);

  const formikAttachments = useMemo(() => formik.values.attachments || [], [formik.values.attachments]);

  /**
   * remove file from the list
   * @param {object} file object with metadata associated to the file stored in logsheet
   * @returns function
   */
  const removeFile = (file) => () => {
    // creating deep copy of formikAttachments, so the change of status will be reflected only in tempFormikValues, but not in tempEquipmentDetail
    // in useEffect for save button state to disabled/enabled in ItemFormDialogBody.jsx
    const formikAttachmentsCopy = JSON.parse(JSON.stringify(formikAttachments));
    const updatedFiles = [...filesData].filter(({ fileId }) => fileId !== file.fileId);
    const FormikUpdatedFiles = formikAttachmentsCopy.map((item) => {
      if (item.fileId === file.fileId) {
        item.status = LOGBOOK_ATTACHMENTS.STATUS.INACTIVE;
      }
      return item;
    });
    //set status to invactive for files already stored
    formik.setFieldValue(LOGBOOK_ATTACHMENTS.ATTACHMENTS, FormikUpdatedFiles);
    //remove new files from the list of files that are going to be saved
    setFiles(updatedFiles);
  };

  const activeFiles = (formikAttachments || []).filter((item) => item.status === LOGBOOK_ATTACHMENTS.STATUS.ACTIVE);

  useEffect(() => {
    if (!!filesData) {
      const fixed = filesData.map(({ __typename, checksum, ...rest }) => ({
        ...rest
      }));

      const files = [...formikAttachments, ...fixed].reduce((acc, curr) => {
        const doesExist = acc.find(({ fileId }) => fileId === curr.fileId);
        if (doesExist) {
          return acc;
        }
        acc.push(curr);
        return acc;
      }, []);

      formik.setFieldValue(LOGBOOK_ATTACHMENTS.ATTACHMENTS, files);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filesData]);

  return (
    <>
      <OwcCard flat style={{ padding: "0px", width: "100%" }}>
        <div
          slot="header"
          style={{
            display: "flex",
            background: "var(--one-color-background-neutral-2)",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "10px",
            border: "1px solid lightgray",
          }}
        >
          <OwcTypography variant="button">
            <strong>File list</strong>
          </OwcTypography>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer"
            }}
          >
            <OwcIcon type="legacy" variant="primary" name="circle_add" />
            <OwcTypography
              variant="subtitle2"
              style={{
                marginLeft: "5px",
                color: "var(--one-color-interaction-default-brand-1)"
              }}
              onClick={openDialog}
            >
              <strong> Add file</strong>
            </OwcTypography>
          </div>
        </div>

        {activeFiles.length ? (
          <div
            style={{
              boxSizing: "border-box",
              height: "200px",
              width: "100%",
              marginTop: 0,
              marginBottom: 0
          }}
          >
            {activeFiles.map((file, index) => (
              <div
                key={`${file.s3FileName}${index}`}
                style={{
                  boxSizing: "border-box",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  borderBottom: "1px solid #C2BAB5AA",
                  height: "20%",
                }}
              >
                <div style={{ padding: "0 12px 0 16px" }}>
                  <FileIcon />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    fontSize: "14px",
                    textWrap: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <div
                    style={{
                      fontSize: "16px",
                      color: "var(--one-color-interaction-default-brand-1)",
                      width: "max-content",
                      maxWidth: "40%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {file.fileName}
                  </div>
                  <div
                    style={{
                      width: "max-content",
                      marginLeft: "1rem",
                      flex: 1,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {file.fileDescription}
                  </div>
                </div>
                <div
                  style={{ padding: "0 8px", cursor: "pointer" }}
                  onClick={() => getFile(file)}
                >
                  <DownloadIcon />
                </div>
                <div
                  style={{ padding: "0 32px 0 16px", cursor: "pointer" }}
                  onClick={removeFile(file)}
                >
                  <BinIcon />
                </div>
              </div>
            ))}
          </div>
        ) : null}
      </OwcCard>
      <ConfirmDialogFiles {...dialogRest} onSave={setFiles} />
    </>
  );
}

export default compose(withApollo)(FileList);
