import DATA_MODEL_TABLE from "./dataModelTable";

const ACTION_LOGS_DATA_MODEL = {
  actionDate: {
    key: "actionDate",
    value: "Action date"
  },
  actionTime: {
    key: "actionTime",
    value: "Action time"
  },
  actionType: {
    key: "action",
    value: "Action type"
  },
  gxpReady: {
    key: "gxpReady",
    value: DATA_MODEL_TABLE?.gxpReady?.value
  },
  systemStatus: {
    key: "systemStatus",
    value: "System status"
  },
  materialNumber: {
    key: "materialNumber",
    value: "Material number"
  },
  ecrNumber: {
    key: "ecrNumber",
    value: "ECR number"
  },
  status: {
    key: "status",
    value: "Equipment Status"
  },
  softwareVerison: {
    key: "updatedSoftwareVersion",
    value: "Software version"
  },
  configurationDetails: {
    key: "logbookConfigurationDetails",
    value: "Configuration details"
  }
};

export default ACTION_LOGS_DATA_MODEL;
