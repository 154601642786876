import React, { useRef } from "react";
import { withApollo, compose } from "react-apollo";
import { connect, useSelector } from "react-redux";
import { signOut } from "../utils/signout";
import IconForApp from "../icons/IconForApp";
import { displayNameSelector } from "../features/user/redux/selectors";
import LOGBOOK_LABEL from "./../utils/constants/logbookLabel";
import { generateID, NotificationCounter } from "@digitallab/grid-common-components";
import { Module } from "../constants";
import { OwcButton, OwcDoubleGlobalArea, OwcIcon, OwcIconButton, OwcPopover, OwcTypography } from "@one/react";
import styles from "./AppBar.module.scss";
import { getEnv } from "../utils/helpers/text";
import { useHistory, useLocation } from "react-router-dom";

const Appbar = ({ client, email, name, landingPageAppSyncClient }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const location = useLocation();
  const history = useHistory();
  const userId = useSelector((state) => state.user.user);
  const popoverElement = useRef(null);

  const handleNotificationViewAll = () => {
    history.push("/notifications");
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const logoLetter = email?.charAt(0);

  const infoInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const currentEnv = getEnv();

  return (
    <>
      <OwcDoubleGlobalArea
        data-testid="app-bar-not-auth-main-page"
        className={currentEnv ? styles.appbarDoubleGlobalAreaWithEnv : styles.appbarDoubleGlobalAreaWithoutEnv}
      >
        <div slot="primary" className={styles.appbarPrimary}>
          <div className={styles.appbarPrimaryDiv}>
            <IconForApp />
            <OwcTypography variant="subtitle1">{LOGBOOK_LABEL.app_name}</OwcTypography>
            <div className={styles.VerticalLine} />
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              className={styles.appbarInfoUserInfo}
              data-testid="app-bar-user-info-user"
              style={{ marginRight: 12, display: "flex", alignItems: "center" }}
            >
              <OwcTypography variant="caption" style={{ marginRight: 10 }}>
                {name}
              </OwcTypography>
              <OwcIcon
                variant="default"
                name="user"
                id="popover-anchor-for-top-bar"
                onClick={handleClick}
                style={{ cursor: "pointer" }}
              />
            </div>
            <NotificationCounter
              handleNotificationViewAll={handleNotificationViewAll}
              landingPageClient={landingPageAppSyncClient}
              isNotificationHighlighted={location.pathname.indexOf("notification") === 1}
              userId={userId}
            />
            <OwcIconButton
              data-testid="App-bar-user-info-info"
              flat
              onClick={() => infoInNewTab(process.env.REACT_APP_INFO_URL)}
              icon="circle_info"
              style={{ marginRight: "12px" }}
            />
            <OwcIcon
              type="legacy"
              style={{
                margin: "0 8px",
                color: "var(--one-color-interaction-default-brand-1)",
                width: "48px"
              }}
              name="roche_logo"
            />
          </div>
        </div>
      </OwcDoubleGlobalArea>

      <OwcPopover
        ref={popoverElement}
        anchor="popover-anchor-for-top-bar"
        id="popover-anchor-for-top-bar-popover"
        anchor-position="bottom-start"
        visible={open}
        disable-portal={true}
        onClickOutside={() => {
          handleClose();
        }}
        className={styles.appbarPopoverWidth}
      >
        <div className={styles.UserInfoSection}>
          <span className={styles.LogoLetter}>{logoLetter}</span>
          <OwcTypography variant="subtitle1">{name}</OwcTypography>
          <OwcTypography variant="body2">{email}</OwcTypography>
        </div>

        <div className={styles.appbarLogoutContainer}>
          <OwcButton
            data-testid="popover-user-menu-footer-section-logout"
            onClick={() => {
              handleClose();
              signOut(client);
            }}
            variant="secondary"
            id={generateID.buttonID(Module.BUTTON.log_out, "button")}
          >
            Logout
          </OwcButton>
        </div>
      </OwcPopover>
    </>
  );
};

const mapStateToProps = (store) => ({
  name: displayNameSelector(store),
  email: store.user.email
});

export default compose(connect(mapStateToProps), withApollo)(Appbar);
