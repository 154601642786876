import gql from "graphql-tag";

export const CREATE_DIGITAL_LAB_LOGBOOK_USER_PROFILE = gql`
  mutation createDigitalLabLogbookUserProfile(
    $email: String!
    $site: String
    $familyName: String
    $givenName: String
    $name: String
    $phone: String
  ) {
    createDigitalLabLogbookUserProfile(
      input: { email: $email, site: $site, familyName: $familyName, givenName: $givenName, name: $name, phone: $phone }
    ) {
      id
      email
      lastFilter
      status
      site
      familyName
      givenName
      name
      user
      phone
    }
  }
`;

export const UPDATE_DIGITAL_LAB_LOGBOOK_USER_PROFILE_PERSONAL_FIELDS = gql`
  mutation updateDigitalLabLogbookUserProfile(
    $id: ID!
    $email: String!
    $site: String
    $familyName: String
    $givenName: String
    $name: String
    $phone: String
    $lastFilter: String
    $lastDisplayColumns: String
    $lastPageSize: String
  ) {
    updateDigitalLabLogbookUserProfile(
      input: {
        id: $id
        email: $email
        site: $site
        familyName: $familyName
        givenName: $givenName
        name: $name
        phone: $phone
        lastFilter: $lastFilter
        lastDisplayColumns: $lastDisplayColumns
        lastPageSize: $lastPageSize
      }
    ) {
      id
      email
      lastFilter
      status
      site
      familyName
      givenName
      name
      user
      phone
    }
  }
`;

export const CREATE_DIGITAL_LAB_LOGBOOK_RUN_LOG_CHANGE = gql`
  mutation createDigitalLabLogbookRunLogChange($input: CreateDigitalLabLogbookRunLogChangeInput) {
    createDigitalLabLogbookRunLogChange(
      input: $input
    ) {
      approverId
      approverUserName
      assay
      defectId
      description
      editReason
      editComment
      equipmentId
      id
      materialNumber
      mediaType {
        key
        value
      }
      modelVersion
      numberOfRuns
      eLNid
      eLNGUID
      gxpReady {
        key
        value
      }
      operatorId
      operatorUserName
      runEndDate
      runEndTime
      runIdentification
      runLogEntryId
      runStartDate
      runStartTime
      runStatus
      samplesProcessed
      serialNumber
      site
      systemStatus {
        key
        value
      }
      tipsUsed {
        key
        value
      }
      instrumentDetails {
        configurationBaseline
        equipmentNickName
        location
        instrumentGxPStatus
        responsiblePerson
        responsibleProxy
        softwareVersion
        manufacturer
      }
    }
  }
`;

export const CREATE_DIGITAL_LAB_LOGBOOK_LOG_SHEET_CHANGE = gql`
  mutation createDigitalLabLogbookLogSheetChange($input: CreateDigitalLabLogbookLogSheetChangeInput) {
    createDigitalLabLogbookLogSheetChange(
      input: $input
    ) {
      action {
        key
        value
      }
      actionDate
      actionTime
      approverId
      approverUserName
      attachments {
        createdAt
        addedBy
        status
        fileName
        fileDescription
        checksum {
          type
          value
        }
        targetModule
        fileId
        groupId
        bucketRegion
        groupMetadataVersion
      }
      description
      editReason
      editComment
      ecrNumber
      equipmentId
      externalDocument {
        link
        name
      }
      id
      instrumentDetails {
        configurationBaseline
        equipmentNickName
        location
        instrumentGxPStatus
        responsiblePerson
        responsibleProxy
        softwareVersion
        manufacturer
      }
      equipmentModel
      logSheetEntryId
      logStatus
      materialNumber
      modelVersion
      operatorId
      operatorUserName
      serialNumber
      site
      systemStatus {
        value
        key
        newValue {
          key
          value
        }
        shouldBePublished
      }
      gxpReady {
        value
        key
        newValue {
          key
          value
        }
        shouldBePublished
      }
      updatedSoftwareVersion {
        newValue
        shouldBePublished
      }
      logbookConfigurationDetails {
        newValue
        shouldBePublished
      }
    }
  }
`;

export const CREATE_DIGITAL_LAB_LOGBOOK_LOG_SHEET_CLUSTER_CHANGE = gql`
  mutation createDigitalLabLogbookClusterLogSheetChange($input: [DigitalLabLogbookClusterLogSheetChangeInput!]) {
    createDigitalLabLogbookClusterLogSheetChange(input: $input) {
      action {
        key
        value
      }
    }
  }
`;

export const CREATE_DIGITAL_LAB_LOGBOOK_RUN_LOG_CLUSTER_CHANGE = gql`
  mutation createDigitalLabLogbookClusterRunLogChange($input: [DigitalLabLogbookClusterRunLogChangeInput!]) {
    createDigitalLabLogbookClusterRunLogChange(input: $input) {
      runLogEntryId
    }
  }
`;
export const CREATE_USER_FILTER = gql`
  mutation createUserFilter($userID: ID!, $filterName: String!, $filterDefinition: String) {
    createUserFilter(input: { userID: $userID, filterName: $filterName, filterDefinition: $filterDefinition }) {
      filterName
      id
      filterDefinition
      userID
    }
  }
`;

export const UPDATE_USER_FILTER = gql`
  mutation updateUserFilter($userID: ID!, $filterName: String!, $filterDefinition: String) {
    updateUserFilter(input: { userID: $userID, filterName: $filterName, filterDefinition: $filterDefinition }) {
      filterName
      id
      filterDefinition
      userID
    }
  }
`;
export const CREATE_USER_DISPLAY_COLUMN = gql`
  mutation createUserDisplay($userID: ID!, $displayName: String!, $displayDefinition: String) {
    createUserDisplay(input: { userID: $userID, displayName: $displayName, displayDefinition: $displayDefinition }) {
      displayName
      id
      displayDefinition
      userID
    }
  }
`;

export const UPDATE_USER_DISPLAY_COLUMN = gql`
  mutation updateUserDisplay($userID: ID!, $displayName: String!, $displayDefinition: String) {
    updateUserDisplay(input: { userID: $userID, displayName: $displayName, displayDefinition: $displayDefinition }) {
      displayName
      id
      displayDefinition
      userID
    }
  }
`;
export const DELETE_USER_DISPLAY_COLUMN = gql`
  mutation deleteUserDisplay($userID: ID!, $displayName: String!) {
    deleteUserDisplay(input: { userID: $userID, displayName: $displayName }) {
      displayName
      id
      displayDefinition
      userID
    }
  }
`;
export const DELETE_USER_FILTER = gql`
  mutation deleteUserFilter($userID: ID!, $filterName: String!) {
    deleteUserFilter(input: { userID: $userID, filterName: $filterName }) {
      filterName
      id
      filterDefinition
      userID
    }
  }
`;

// status ACTIVE
export const ADD_ATTACHMENTS_TO_LOGSHEET = gql`
  mutation addAttachmentsToLogsheet(
    $fileDescription: String
    $fileName: String
    $originalFileName: String
    $staus: String
    $targetModule: String
  ) {
    addAttachmentsToLogsheet(
      input: {
        fileDescription: $fileDescription
        fileName: $fileName
        originalFileName: $originalFileName
        staus: $staus
        targetModule: $targetModule
      }
    ) {
      statusCode
    }
  }
`;

export const CREATE_BULK_DIGITAL_LAB_LOGBOOK_ACTION_LOG = gql`
  mutation createBulkDigitalLabLogbookLogSheetChange($input: CreateBulkDigitalLabLogbookLogSheetChangeInput!) {
    createBulkDigitalLabLogbookLogSheetChange(input: $input) {
      logSheetEntryId
      inventoryId
    }
  }
`;
