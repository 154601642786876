import { OwcInput, OwcSlideToggle } from "@one/react";
import { FormikProps } from "formik";

export const SoftwareInputWithSwitch = ({
  formik,
  isPropagationDisabled,
  editMode,
}: {
  formik: FormikProps<{
    updatedSoftwareVersion: {
      newValue: string;
      shouldBePublished: boolean
    };
    action: string
  }>;
  isPropagationDisabled?: boolean;
  editMode: boolean;
}) => {
  return formik.values.action === "OTHER_SOFTWARE_CHANGE" ? (
    <>
      <OwcInput
        onBlur={formik.handleBlur}
        data-testid="action-data-inputs-new-software-version-input"
        variant="filled"
        id="updatedSoftwareVersion.newValue"
        name="updatedSoftwareVersion.newValue"
        label={"New software version"}
        value={formik.values.updatedSoftwareVersion.newValue}
        onInputChange={formik.handleChange}
        onInputKeyDown={formik.handleChange}
        style={{
          width: "100%",
        }}
      />
      <OwcSlideToggle
        checked={formik.values.updatedSoftwareVersion.shouldBePublished}
        onInputChange={(event: { detail: any }) =>
          formik.setFieldValue("updatedSoftwareVersion.shouldBePublished", event.detail)
        }
        id="updatedSoftwareVersion.shouldBePublished"
        disabled={editMode || isPropagationDisabled}
      >
        Publish “New software version” to Equipment Repository
      </OwcSlideToggle>
    </>
  ) : (
    <></>
  );
};
