import gql from "graphql-tag";
// Normal Run log view===============
// CR: as mentioned thos gql should be optimized to use only download columns that are used/needed.
export const RUN_LOG_BY_SITE_AND_START_DATE_DESC = gql`
  query runLogBySiteAndStartDate(
    $site: String
    $dateRange: [String]
    $limit: Int
    $nextToken: String
    $filter: ModelDigitalLabLogbookRunLogFilterInput
  ) {
    runLogBySiteAndStartDate(
      sortDirection: DESC
      site: $site
      runStartDate: { between: $dateRange }
      limit: $limit
      nextToken: $nextToken
      filter: $filter
    ) {
      nextToken
      items {
        id
        runStartTime
        runStartDate
        runEndTime
        runEndDate
        materialNumber
        serialNumber
        eLNid
        site
        equipmentId
        equipmentModel
        gxpReady {
          key
          value
        }
        instrumentDetails {
          configurationBaseline
          equipmentNickName
          location
          instrumentGxPStatus
          responsiblePerson
          responsibleProxy
          softwareVersion
          manufacturer
        }
        equipmentDetails {
          addInfo {
            currentGxPStatusFromLogs
            currentSystemStatusFromLogs
            id
            inventoryId
          }
          belongingToGroup
          buildingLocation {
            key
            value
          }
          cluster
          clusterId
          comment
          configurationBaseline
          costCenter
          createdAt
          createdBy
          csv
          datamodelVersion
          dateOfLastMaintanance
          dateOfNextMaintanance
          dateOfNextPeriodicReview
          displayImage
          entryType
          electronicRecord
          electronicSignatures
          equipmentAdministrator
          equipmentCategory
          equipmentId
          equipmentModel
          equipmentNickName
          floor {
            key
            value
          }
          functionalLocation
          gxpRelevant
          installedTests {
            name
            version
          }
          instrumentGTIN
          instrumentRUDI
          inventoryId
          inventoryNumber
          isBookable
          isVisualized
          linkedInstance
          location
          maintenancePlan
          manufacturer
          materialNumber
          modelName
          qualificationStatus
          responsiblePerson
          responsibleProxy
          room {
            key
            value
          }
          secondaryID
          secondaryIDMapping
          serialNumber
          shortIdentifier
          siteId
          siteName
          siteTimezone
          softwareVersion
          sop {
            key
            value
          }
          status
          systemOwner
          systemStatus
          updatedAt
          updatedBy
          variant
        }
        clusterEntryRefId
        runStatus
        description
        numberOfRuns
        samplesProcessed
        defectId
        approverId
        approverUserName
        assay
        mediaType {
          key
          value
        }
        tipsUsed {
          key
          value
        }
        operatorId
        operatorUserName
        systemStatus {
          key
          value
        }
        createdAt
        runIdentification
        inventoryId
        modelVersion
        subEquipment {
          inventoryId
          shortIdentifier
          manufacturer
          equipmentModel
          equipmentNickName
          serialNumber
          equipmentId
          location
        }
      }
    }
  }
`;

export const RUN_LOG_BY_SITE_AND_START_DATE_ASC = gql`
  query runLogBySiteAndStartDate(
    $site: String
    $dateRange: [String]
    $limit: Int
    $nextToken: String
    $filter: ModelDigitalLabLogbookRunLogFilterInput
  ) {
    runLogBySiteAndStartDate(
      sortDirection: ASC
      site: $site
      runStartDate: { between: $dateRange }
      limit: $limit
      nextToken: $nextToken
      filter: $filter
    ) {
      nextToken
      items {
        id
        runStartTime
        runStartDate
        runEndTime
        runEndDate
        materialNumber
        serialNumber
        eLNid
        site
        equipmentId
        equipmentModel
        instrumentDetails {
          configurationBaseline
          equipmentNickName
          location
          instrumentGxPStatus
          responsiblePerson
          responsibleProxy
          softwareVersion
          manufacturer
        }
        equipmentDetails {
          addInfo {
            currentGxPStatusFromLogs
            currentSystemStatusFromLogs
            id
            inventoryId
          }
          belongingToGroup
          buildingLocation {
            key
            value
          }
          cluster
          clusterId
          comment
          configurationBaseline
          costCenter
          createdAt
          createdBy
          csv
          datamodelVersion
          dateOfLastMaintanance
          dateOfNextMaintanance
          dateOfNextPeriodicReview
          displayImage
          entryType
          electronicRecord
          electronicSignatures
          equipmentAdministrator
          equipmentCategory
          equipmentId
          equipmentModel
          equipmentNickName
          floor {
            key
            value
          }
          functionalLocation
          gxpRelevant
          installedTests {
            name
            version
          }
          instrumentGTIN
          instrumentRUDI
          inventoryId
          inventoryNumber
          isBookable
          isVisualized
          linkedInstance
          location
          maintenancePlan
          manufacturer
          materialNumber
          modelName
          qualificationStatus
          responsiblePerson
          responsibleProxy
          room {
            key
            value
          }
          secondaryID
          secondaryIDMapping
          serialNumber
          shortIdentifier
          siteId
          siteName
          siteTimezone
          softwareVersion
          sop {
            key
            value
          }
          status
          systemOwner
          systemStatus
          updatedAt
          updatedBy
          variant
        }
        clusterEntryRefId
        runStatus
        description
        numberOfRuns
        samplesProcessed
        defectId
        approverId
        approverUserName
        assay
        mediaType {
          key
          value
        }
        tipsUsed {
          key
          value
        }
        operatorId
        operatorUserName
        systemStatus
        subEquipment {
          inventoryId
          shortIdentifier
          manufacturer
          equipmentModel
          equipmentNickName
          serialNumber
          equipmentId
          location
        }
        createdAt
        runIdentification
        inventoryId
        modelVersion
      }
    }
  }
`;
// Normal Run log view===============
// Equipment centric Run log view===========
export const RUN_LOG_BY_INVENTORY_ID_DESC = gql`
  query runLogBySiteAndInventoryIdRunStartDate(
    $site: String
    $inventoryId: String
    $dateRangeBegin: String
    $dateRangeEnd: String
    $limit: Int
    $nextToken: String
    $filter: ModelDigitalLabLogbookRunLogFilterInput
  ) {
    runLogBySiteAndStartDate: runLogBySiteAndInventoryIdRunStartDate(
      sortDirection: DESC
      site: $site
      limit: $limit
      nextToken: $nextToken
      filter: $filter
      inventoryIdRunStartDate: {
        between: [
          { inventoryId: $inventoryId, runStartDate: $dateRangeBegin }
          { inventoryId: $inventoryId, runStartDate: $dateRangeEnd }
        ]
      }
    ) {
      nextToken
      items {
        id
        runStartTime
        runStartDate
        runEndTime
        runEndDate
        materialNumber
        serialNumber
        eLNid
        site
        equipmentId
        equipmentModel
        gxpReady {
          key
          value
        }
        gxpReadyCluster {
          key
          value
        }
        instrumentDetails {
          configurationBaseline
          equipmentNickName
          location
          instrumentGxPStatus
          responsiblePerson
          responsibleProxy
          softwareVersion
          manufacturer
        }
        equipmentDetails {
          addInfo {
            currentGxPStatusFromLogs
            currentSystemStatusFromLogs
            id
            inventoryId
          }
          belongingToGroup
          buildingLocation {
            key
            value
          }
          cluster
          clusterId
          comment
          configurationBaseline
          costCenter
          createdAt
          createdBy
          csv
          datamodelVersion
          dateOfLastMaintanance
          dateOfNextMaintanance
          dateOfNextPeriodicReview
          displayImage
          entryType
          electronicRecord
          electronicSignatures
          equipmentAdministrator
          equipmentCategory
          equipmentId
          equipmentModel
          equipmentNickName
          floor {
            key
            value
          }
          functionalLocation
          gxpRelevant
          installedTests {
            name
            version
          }
          instrumentGTIN
          instrumentRUDI
          inventoryId
          inventoryNumber
          isBookable
          isVisualized
          linkedInstance
          location
          maintenancePlan
          manufacturer
          materialNumber
          modelName
          qualificationStatus
          responsiblePerson
          responsibleProxy
          room {
            key
            value
          }
          secondaryID
          secondaryIDMapping
          serialNumber
          shortIdentifier
          siteId
          siteName
          siteTimezone
          softwareVersion
          sop {
            key
            value
          }
          status
          systemOwner
          systemStatus
          updatedAt
          updatedBy
          variant
        }
        clusterEntryRefId
        runStatus
        description
        numberOfRuns
        samplesProcessed
        defectId
        approverId
        approverUserName
        assay
        mediaType {
          key
          value
        }
        tipsUsed {
          key
          value
        }
        operatorId
        operatorUserName
        systemStatus {
          key
          value
        }
        systemStatusCluster {
          key
          value
        }
        createdAt
        updatedAt
        runIdentification
        inventoryId
        modelVersion
        subEquipment {
          inventoryId
          shortIdentifier
        }
      }
    }
  }
`;

export const RUN_LOG_BY_INVENTORY_ID_ASC = gql`
  query runLogBySiteAndInventoryIdRunStartDate(
    $site: String
    $inventoryId: String
    $dateRangeBegin: String
    $dateRangeEnd: String
    $limit: Int
    $nextToken: String
    $filter: ModelDigitalLabLogbookRunLogFilterInput
  ) {
    runLogBySiteAndInventoryIdRunStartDate(
      sortDirection: ASC
      site: $site
      limit: $limit
      nextToken: $nextToken
      filter: $filter
      inventoryIdRunStartDate: {
        between: [
          { inventoryId: $inventoryId, runStartDate: $dateRangeBegin }
          { inventoryId: $inventoryId, runStartDate: $dateRangeEnd }
        ]
      }
    ) {
      nextToken
      items {
        id
        runStartTime
        runStartDate
        runEndTime
        runEndDate
        materialNumber
        serialNumber
        eLNid
        site
        equipmentId
        equipmentModel
        instrumentDetails {
          configurationBaseline
          equipmentNickName
          location
          instrumentGxPStatus
          responsiblePerson
          responsibleProxy
          softwareVersion
          manufacturer
        }
        equipmentDetails {
          addInfo {
            currentGxPStatusFromLogs
            currentSystemStatusFromLogs
            id
            inventoryId
          }
          belongingToGroup
          buildingLocation {
            key
            value
          }
          cluster
          clusterId
          comment
          configurationBaseline
          costCenter
          createdAt
          createdBy
          csv
          datamodelVersion
          dateOfLastMaintanance
          dateOfNextMaintanance
          dateOfNextPeriodicReview
          displayImage
          entryType
          electronicRecord
          electronicSignatures
          equipmentAdministrator
          equipmentCategory
          equipmentId
          equipmentModel
          equipmentNickName
          floor {
            key
            value
          }
          functionalLocation
          gxpRelevant
          installedTests {
            name
            version
          }
          instrumentGTIN
          instrumentRUDI
          inventoryId
          inventoryNumber
          isBookable
          isVisualized
          linkedInstance
          location
          maintenancePlan
          manufacturer
          materialNumber
          modelName
          qualificationStatus
          responsiblePerson
          responsibleProxy
          room {
            key
            value
          }
          secondaryID
          secondaryIDMapping
          serialNumber
          shortIdentifier
          siteId
          siteName
          siteTimezone
          softwareVersion
          sop {
            key
            value
          }
          status
          systemOwner
          systemStatus
          updatedAt
          updatedBy
          variant
        }
        clusterEntryRefId
        runStatus
        description
        numberOfRuns
        samplesProcessed
        defectId
        approverId
        approverUserName
        assay
        mediaType {
          key
          value
        }
        tipsUsed {
          key
          value
        }
        operatorId
        operatorUserName
        systemStatus
        createdAt
        runIdentification
        inventoryId
        modelVersion
        subEquipment {
          inventoryId
          shortIdentifier
        }
      }
    }
  }
`;
// Equipment centric Run log view===========
// Normal Action log view=================
export const LOG_SHEET_BY_SITE_AND_ACTION_DATE_TIME_DESC = gql`
  query logSheetBySiteAndActionDateTime(
    $site: String
    $dateRangeBegin: AWSDate
    $dateRangeEnd: AWSDate
    $limit: Int
    $nextToken: String
    $filter: ModelDigitalLabLogbookLogSheetFilterInput
  ) {
    logSheetBySiteAndActionDateTime(
      sortDirection: DESC
      site: $site
      actionDateActionTime: {
        between: [
          { actionDate: $dateRangeBegin, actionTime: "00:00:00" }
          { actionDate: $dateRangeEnd, actionTime: "23:59:59" }
        ]
      }
      limit: $limit
      nextToken: $nextToken
      filter: $filter
    ) {
      nextToken
      items {
        action {
          key
          value
        }
        actionDate
        actionTime
        approverId
        approverUserName
        description
        ecrNumber
        equipmentId
        externalDocument {
          link
          name
        }
        attachments {
          createdAt
          addedBy
          status
          fileName
          fileDescription
          checksum {
            type
            value
          }
          targetModule
          fileId
          groupId
          bucketRegion
          groupMetadataVersion
        }
        gxpReadyCluster {
          key
          value
        }
        id
        instrumentDetails {
          configurationBaseline
          equipmentNickName
          location
          instrumentGxPStatus
          responsiblePerson
          responsibleProxy
          softwareVersion
          manufacturer
        }
        equipmentDetails {
          addInfo {
            currentGxPStatusFromLogs
            currentSystemStatusFromLogs
            id
            inventoryId
          }
          belongingToGroup
          buildingLocation {
            key
            value
          }
          cluster
          clusterId
          comment
          configurationBaseline
          costCenter
          createdAt
          createdBy
          csv
          datamodelVersion
          dateOfLastMaintanance
          dateOfNextMaintanance
          dateOfNextPeriodicReview
          displayImage
          entryType
          electronicRecord
          electronicSignatures
          equipmentAdministrator
          equipmentCategory
          equipmentId
          equipmentModel
          equipmentNickName
          floor {
            key
            value
          }
          functionalLocation
          gxpRelevant
          installedTests {
            name
            version
          }
          instrumentGTIN
          instrumentRUDI
          inventoryId
          inventoryNumber
          isBookable
          isVisualized
          linkedInstance
          location
          maintenancePlan
          manufacturer
          materialNumber
          modelName
          qualificationStatus
          responsiblePerson
          responsibleProxy
          room {
            key
            value
          }
          secondaryID
          secondaryIDMapping
          serialNumber
          shortIdentifier
          siteId
          siteName
          siteTimezone
          softwareVersion
          sop {
            key
            value
          }
          status
          systemOwner
          systemStatus
          updatedAt
          updatedBy
          variant
        }
        clusterEntryRefId
        equipmentModel
        logStatus
        materialNumber
        operatorId
        operatorUserName
        serialNumber
        site
        systemStatusCluster {
          key
          value
        }
        isClusterQualificationChanged
        subEquipment {
          inventoryId
          shortIdentifier
          manufacturer
          equipmentModel
          equipmentNickName
          serialNumber
          equipmentId
          location
        }
        createdAt
        updatedAt
        inventoryId
        modelVersion
        systemStatus {
          value
          newValue {
            value
            key
          }
          key
        }
        gxpReady {
          value
          newValue {
            value
            key
          }
          key
        }
        updatedSoftwareVersion {
          newValue
        }
        logbookConfigurationDetails {
          newValue
        }
      }
    }
  }
`;
export const LOG_SHEET_BY_SITE_AND_ACTION_DATE_TIME_ASC = gql`
  query logSheetBySiteAndActionDateTime(
    $site: String
    $dateRangeBegin: AWSDate
    $dateRangeEnd: AWSDate
    $limit: Int
    $nextToken: String
    $filter: ModelDigitalLabLogbookLogSheetFilterInput
  ) {
    logSheetBySiteAndActionDateTime(
      sortDirection: ASC
      site: $site
      actionDateActionTime: {
        between: [
          { actionDate: $dateRangeBegin, actionTime: "00:00:00" }
          { actionDate: $dateRangeEnd, actionTime: "23:59:59" }
        ]
      }
      limit: $limit
      nextToken: $nextToken
      filter: $filter
    ) {
      nextToken
      items {
        action {
          key
          value
        }
        actionDate
        actionTime
        approverId
        approverUserName
        description
        ecrNumber
        equipmentId
        externalDocument {
          link
          name
        }
        attachments {
          createdAt
          addedBy
          status
          fileName
          fileDescription
          checksum {
            type
            value
          }
          targetModule
          fileId
          groupId
          bucketRegion
          groupMetadataVersion
        }
        id
        instrumentDetails {
          configurationBaseline
          equipmentNickName
          location
          instrumentGxPStatus
          responsiblePerson
          responsibleProxy
          softwareVersion
          manufacturer
        }
        equipmentDetails {
          addInfo {
            currentGxPStatusFromLogs
            currentSystemStatusFromLogs
            id
            inventoryId
          }
          belongingToGroup
          buildingLocation {
            key
            value
          }
          cluster
          clusterId
          comment
          configurationBaseline
          costCenter
          createdAt
          createdBy
          csv
          datamodelVersion
          dateOfLastMaintanance
          dateOfNextMaintanance
          dateOfNextPeriodicReview
          displayImage
          entryType
          electronicRecord
          electronicSignatures
          equipmentAdministrator
          equipmentCategory
          equipmentId
          equipmentModel
          equipmentNickName
          floor {
            key
            value
          }
          functionalLocation
          gxpRelevant
          installedTests {
            name
            version
          }
          instrumentGTIN
          instrumentRUDI
          inventoryId
          inventoryNumber
          isBookable
          isVisualized
          linkedInstance
          location
          maintenancePlan
          manufacturer
          materialNumber
          modelName
          qualificationStatus
          responsiblePerson
          responsibleProxy
          room {
            key
            value
          }
          secondaryID
          secondaryIDMapping
          serialNumber
          shortIdentifier
          siteId
          siteName
          siteTimezone
          softwareVersion
          sop {
            key
            value
          }
          status
          systemOwner
          systemStatus
          updatedAt
          updatedBy
          variant
        }
        clusterEntryRefId
        equipmentModel
        logStatus
        materialNumber
        operatorId
        operatorUserName
        serialNumber
        site
        subEquipment {
          inventoryId
          shortIdentifier
          manufacturer
          equipmentModel
          equipmentNickName
          serialNumber
          equipmentId
          location
        }
        createdAt
        inventoryId
        modelVersion
        systemStatus {
          value
          newValue {
            value
            key
          }
          key
        }
        gxpReady {
          value
          newValue {
            value
            key
          }
          key
        }
        updatedSoftwareVersion {
          newValue
        }
        logbookConfigurationDetails {
          newValue
        }
      }
    }
  }
`;

// Normal Action log view=================
// Equipment centric Action log view================
export const LOG_SHEET_BY_INVENTRY_ID_AND_ACTION_DATE_TIME_DESC = gql`
  query logSheetByInvIdAndActionDateTime(
    $inventoryId: String
    $dateRangeBegin: AWSDate
    $dateRangeEnd: AWSDate
    $limit: Int
    $nextToken: String
    $filter: ModelDigitalLabLogbookLogSheetFilterInput
  ) {
    logSheetBySiteAndActionDateTime: logSheetByInvIdAndActionDateTime(
      inventoryId: $inventoryId
      sortDirection: DESC
      filter: $filter
      actionDateActionTime: {
        between: [
          { actionDate: $dateRangeBegin, actionTime: "00:00:00" }
          { actionDate: $dateRangeEnd, actionTime: "23:59:59" }
        ]
      }
      limit: $limit
      nextToken: $nextToken
    ) {
      nextToken
      items {
        action {
          key
          value
        }
        actionDate
        actionTime
        approverId
        approverUserName
        description
        ecrNumber
        equipmentId
        gxpReadyCluster {
          key
          value
        }
        externalDocument {
          link
          name
        }
        attachments {
          createdAt
          addedBy
          status
          fileName
          fileDescription
          checksum {
            type
            value
          }
          targetModule
          fileId
          groupId
          bucketRegion
          groupMetadataVersion
        }
        id
        instrumentDetails {
          configurationBaseline
          equipmentNickName
          location
          instrumentGxPStatus
          responsiblePerson
          responsibleProxy
          softwareVersion
          manufacturer
        }
        equipmentDetails {
          addInfo {
            currentGxPStatusFromLogs
            currentSystemStatusFromLogs
            id
            inventoryId
          }
          belongingToGroup
          buildingLocation {
            key
            value
          }
          cluster
          clusterId
          comment
          configurationBaseline
          costCenter
          createdAt
          createdBy
          entryType
          csv
          datamodelVersion
          dateOfLastMaintanance
          dateOfNextMaintanance
          dateOfNextPeriodicReview
          displayImage
          electronicRecord
          electronicSignatures
          equipmentAdministrator
          equipmentCategory
          equipmentId
          equipmentModel
          equipmentNickName
          floor {
            key
            value
          }
          functionalLocation
          gxpRelevant
          installedTests {
            name
            version
          }
          instrumentGTIN
          instrumentRUDI
          inventoryId
          inventoryNumber
          isBookable
          isVisualized
          linkedInstance
          location
          maintenancePlan
          manufacturer
          materialNumber
          modelName
          qualificationStatus
          responsiblePerson
          responsibleProxy
          room {
            key
            value
          }
          secondaryID
          secondaryIDMapping
          serialNumber
          shortIdentifier
          siteId
          siteName
          siteTimezone
          softwareVersion
          sop {
            key
            value
          }
          status
          systemOwner
          systemStatus
          updatedAt
          updatedBy
          variant
        }
        clusterEntryRefId
        equipmentModel
        logStatus
        materialNumber
        operatorId
        operatorUserName
        serialNumber
        site
        systemStatusCluster {
          key
          value
        }
        isClusterQualificationChanged
        createdAt
        updatedAt
        inventoryId
        modelVersion
        subEquipment {
          inventoryId
          shortIdentifier
        }
        systemStatus {
          value
          newValue {
            value
            key
          }
          key
        }
        gxpReady {
          value
          newValue {
            value
            key
          }
          key
        }
        updatedSoftwareVersion {
          newValue
        }
        logbookConfigurationDetails {
          newValue
        }
      }
    }
  }
`;

export const LOG_SHEET_BY_INVENTRY_ID_AND_ACTION_DATE_TIME_ASC = gql`
  query logSheetByInvIdAndActionDateTime(
    $inventoryId: String
    $dateRangeBegin: AWSDate
    $dateRangeEnd: AWSDate
    $limit: Int
    $nextToken: String
    $filter: ModelDigitalLabLogbookLogSheetFilterInput
  ) {
    logSheetBySiteAndActionDateTime: logSheetByInvIdAndActionDateTime(
      inventoryId: $inventoryId
      sortDirection: ASC
      filter: $filter
      actionDateActionTime: {
        between: [
          { actionDate: $dateRangeBegin, actionTime: "00:00:00" }
          { actionDate: $dateRangeEnd, actionTime: "23:59:59" }
        ]
      }
      limit: $limit
      nextToken: $nextToken
    ) {
      nextToken
      items {
        action {
          key
          value
        }
        actionDate
        actionTime
        approverId
        approverUserName
        description
        ecrNumber
        equipmentId
        gxpReadyCluster {
          key
          value
        }
        externalDocument {
          link
          name
        }
        attachments {
          createdAt
          addedBy
          status
          fileName
          fileDescription
          checksum {
            type
            value
          }
          targetModule
          fileId
          groupId
          bucketRegion
          groupMetadataVersion
        }
        id
        instrumentDetails {
          configurationBaseline
          equipmentNickName
          location
          instrumentGxPStatus
          responsiblePerson
          responsibleProxy
          softwareVersion
          manufacturer
        }
        equipmentDetails {
          addInfo {
            currentGxPStatusFromLogs
            currentSystemStatusFromLogs
            id
            inventoryId
          }
          belongingToGroup
          buildingLocation {
            key
            value
          }
          cluster
          clusterId
          comment
          configurationBaseline
          costCenter
          createdAt
          createdBy
          entryType
          csv
          datamodelVersion
          dateOfLastMaintanance
          dateOfNextMaintanance
          dateOfNextPeriodicReview
          displayImage
          electronicRecord
          electronicSignatures
          equipmentAdministrator
          equipmentCategory
          equipmentId
          equipmentModel
          equipmentNickName
          floor {
            key
            value
          }
          functionalLocation
          gxpRelevant
          installedTests {
            name
            version
          }
          instrumentGTIN
          instrumentRUDI
          inventoryId
          inventoryNumber
          isBookable
          isVisualized
          linkedInstance
          location
          maintenancePlan
          manufacturer
          materialNumber
          modelName
          qualificationStatus
          responsiblePerson
          responsibleProxy
          room {
            key
            value
          }
          secondaryID
          secondaryIDMapping
          serialNumber
          shortIdentifier
          siteId
          siteName
          siteTimezone
          softwareVersion
          sop {
            key
            value
          }
          status
          systemOwner
          systemStatus
          updatedAt
          updatedBy
          variant
        }
        clusterEntryRefId
        equipmentModel
        logStatus
        materialNumber
        operatorId
        operatorUserName
        serialNumber
        site
        systemStatusCluster {
          key
          value
        }
        isClusterQualificationChanged
        createdAt
        inventoryId
        modelVersion
        subEquipment {
          inventoryId
          shortIdentifier
        }
        systemStatus {
          value
          newValue {
            value
            key
          }
          key
        }
        gxpReady {
          value
          newValue {
            value
            key
          }
          key
        }
        updatedSoftwareVersion {
          newValue
        }
        logbookConfigurationDetails {
          newValue
        }
      }
    }
  }
`;

// Equipment centric Action log view================
export const LOG_SHEET_CHANGE_BY_LOG_SHEET_ENTRY_ID = gql`
  query logSheetChangeByLogSheetEntryId($logSheetEntryId: ID) {
    logSheetChangeByLogSheetEntryId(logSheetEntryId: $logSheetEntryId) {
      items {
        action {
          key
          value
        }
        actionDate
        actionTime
        approverId
        approverUserName
        description
        editReason
        editComment
        createdAt
        ecrNumber
        equipmentId
        externalDocument {
          link
          name
        }
        attachments {
          createdAt
          addedBy
          status
          fileName
          fileDescription
          checksum {
            type
            value
          }
          targetModule
          fileId
          groupId
          bucketRegion
          groupMetadataVersion
        }
        id
        instrumentDetails {
          configurationBaseline
          equipmentNickName
          location
          instrumentGxPStatus
          responsiblePerson
          responsibleProxy
          softwareVersion
          manufacturer
        }
        equipmentDetails {
          secondaryIDMapping
          linkedInstance
          equipmentCategory
          variant
          serialNumber
          equipmentId
          materialNumber
          equipmentModel
          equipmentNickName
          location
          manufacturer
          entryType
        }
        clusterEntryRefId
        logSheetEntryId
        logStatus
        materialNumber
        operatorId
        operatorUserName
        serialNumber
        site
        inventoryId
        subEquipment {
          inventoryId
          shortIdentifier
          manufacturer
          equipmentModel
          equipmentNickName
          serialNumber
          equipmentId
          location
        }
        gxpReadyCluster {
          key
          value
        }
        systemStatusCluster {
          key
          value
        }
        isClusterQualificationChanged
        systemStatus {
          value
          key
          newValue {
            key
            value
          }
          shouldBePublished
        }
        gxpReady {
          value
          key
          newValue {
            key
            value
          }
          shouldBePublished
        }
        updatedSoftwareVersion {
          newValue
          shouldBePublished
        }
        logbookConfigurationDetails {
          newValue
          shouldBePublished
        }
      }
    }
  }
`;

export const RUN_LOG_CHANGE_BY_RUN_LOG_ENTRY_ID = gql`
  query runLogChangeByRunLogEntryId($runLogEntryId: ID) {
    runLogChangeByRunLogEntryId(runLogEntryId: $runLogEntryId) {
      items {
        approverId
        approverUserName
        assay
        defectId
        description
        editReason
        editComment
        equipmentId
        id
        instrumentDetails {
          configurationBaseline
          equipmentNickName
          location
          instrumentGxPStatus
          responsiblePerson
          responsibleProxy
          softwareVersion
          manufacturer
        }
        equipmentDetails {
          secondaryIDMapping
          linkedInstance
          equipmentCategory
          variant
          serialNumber
          equipmentId
          materialNumber
          equipmentModel
          equipmentNickName
          location
          manufacturer
        }
        materialNumber
        mediaType {
          key
          value
        }
        numberOfRuns
        operatorId
        operatorUserName
        eLNid
        runEndDate
        runEndTime
        createdAt
        runLogEntryId
        runStartDate
        runStartTime
        runStatus
        samplesProcessed
        runIdentification
        serialNumber
        systemStatus {
          key
          value
        }
        site
        tipsUsed {
          key
          value
        }
        inventoryId
        modelVersion
        subEquipment {
          inventoryId
          shortIdentifier
          manufacturer
          equipmentModel
          equipmentNickName
          serialNumber
          equipmentId
          location
        }
      }
    }
  }
`;

export const INSTRUMENT_BY_SITE_WITH_FILTER = gql`
  query instrumentBySite(
    $siteName: String
    $limit: Int
    $nextToken: String
    $filter: ModelDigitalLabLogbookInstrumentFilterInput
  ) {
    instrumentBySite(siteName: $siteName, limit: $limit, nextToken: $nextToken, filter: $filter) {
      items {
        addInfo {
          currentGxPStatusFromLogs
          currentSystemStatusFromLogs
          id
          inventoryId
        }
        belongingToGroup
        buildingLocation {
          key
          value
        }
        cluster
        comment
        configurationBaseline
        costCenter
        createdAt
        createdBy
        csv
        datamodelVersion
        dateOfLastMaintanance
        dateOfNextMaintanance
        dateOfNextPeriodicReview
        displayImage
        electronicRecord
        electronicSignatures
        equipmentAdministrator
        equipmentCategory
        equipmentId
        equipmentModel
        equipmentNickName
        floor {
          key
          value
        }
        functionalLocation
        gxpRelevant
        installedTests {
          name
          version
        }
        instrumentGTIN
        instrumentRUDI
        inventoryId
        inventoryNumber
        isBookable
        isVisualized
        linkedInstance
        location
        maintenancePlan
        manufacturer
        materialNumber
        modelName
        qualificationStatus
        responsiblePerson
        responsibleProxy
        room {
          key
          value
        }
        secondaryID
        secondaryIDMapping
        serialNumber
        shortIdentifier
        siteId
        siteName
        siteTimezone
        softwareVersion
        sop {
          key
          value
        }
        status
        systemOwner
        systemStatus
        updatedAt
        updatedBy
        variant
      }
      nextToken
    }
  }
`;

export const INSTRUMENT_BY_SITE = gql`
  query instrumentBySite($siteName: String, $limit: Int, $nextToken: String) {
    instrumentBySite(siteName: $siteName, limit: $limit, nextToken: $nextToken) {
      items {
        equipmentModel
      }
      nextToken
    }
  }
`;
export const LIST_DIGITAL_LAB_LOGBOOK_USER_PROFILES = gql`
  query listDigitalLabLogbookUserProfiles($limit: Int, $nextToken: String) {
    listDigitalLabLogbookUserProfiles(limit: $limit, nextToken: $nextToken) {
      items {
        email
        familyName
        givenName
        user
        name
      }
      nextToken
    }
  }
`;
export const LIST_DIGITAL_LAB_LOGBOOK_TIPS = gql`
  query listDigitalLabLogbookTips($limit: Int, $nextToken: String) {
    listDigitalLabLogbookTips(limit: $limit, nextToken: $nextToken) {
      items {
        value
        key
      }
      nextToken
    }
  }
`;

export const LIST_LOG_SHEET_ACTIONS = gql`
  query listLogSheetActions($limit: Int, $nextToken: String) {
    listLogSheetActions(limit: $limit, nextToken: $nextToken) {
      items {
        value
        key
      }
      nextToken
    }
  }
`;

export const LIST_LOG_SHEET_GXP_READYS = gql`
  query listLogSheetGxpReadys($limit: Int, $nextToken: String) {
    listLogSheetGxpReadys(limit: $limit, nextToken: $nextToken) {
      items {
        value
        key
      }
      nextToken
    }
  }
`;
export const LIST_LOG_SHEET_SYSTEM_STATUSS = gql`
  query listLogSheetSystemStatuss($limit: Int, $nextToken: String) {
    listLogSheetSystemStatuss(limit: $limit, nextToken: $nextToken) {
      items {
        value
        key
      }
      nextToken
    }
  }
`;

export const LIST_DIGITAL_LAB_LOGBOOK_MEDIA_TYPES = gql`
  query listDigitalLabLogbookMediaTypes($limit: Int, $nextToken: String) {
    listDigitalLabLogbookMediaTypes(limit: $limit, nextToken: $nextToken) {
      items {
        value
        key
      }
      nextToken
    }
  }
`;

export const INSTRUMENT_BY_SITE_AND_EQUIPMENT_ID = gql`
  query instrumentBySiteAndEquipmentId(
    $equipmentId: String!
    $nextToken: String
    $siteName: String!
    $limit: Int
    $filter: ModelDigitalLabLogbookInstrumentFilterInput
  ) {
    instrumentBySiteAndEquipmentId(
      equipmentId: { beginsWith: $equipmentId }
      nextToken: $nextToken
      siteName: $siteName
      limit: $limit
      filter: $filter
    ) {
      items {
        addInfo {
          currentGxPStatusFromLogs
          currentSystemStatusFromLogs
          id
          inventoryId
        }
        belongingToGroup
        buildingLocation {
          key
          value
        }
        entryType
        cluster
        comment
        configurationBaseline
        costCenter
        createdAt
        createdBy
        csv
        datamodelVersion
        dateOfLastMaintanance
        dateOfNextMaintanance
        dateOfNextPeriodicReview
        displayImage
        electronicRecord
        electronicSignatures
        equipmentAdministrator
        equipmentCategory
        equipmentId
        equipmentModel
        equipmentNickName
        floor {
          key
          value
        }
        functionalLocation
        gxpRelevant
        installedTests {
          name
          version
        }
        instrumentGTIN
        instrumentRUDI
        inventoryId
        inventoryNumber
        isBookable
        isVisualized
        linkedInstance
        location
        maintenancePlan
        manufacturer
        materialNumber
        modelName
        qualificationStatus
        responsiblePerson
        responsibleProxy
        room {
          key
          value
        }
        secondaryID
        secondaryIDMapping
        serialNumber
        shortIdentifier
        siteId
        siteName
        siteTimezone
        softwareVersion
        sop {
          key
          value
        }
        status
        systemOwner
        systemStatus
        updatedAt
        updatedBy
        variant
      }
      nextToken
    }
  }
`;

export const INSTRUMENT_BY_SITE_AND_SERIAL_NUMBER = gql`
  query instrumentBySiteAndSerialNumber(
    $serialNumber: String!
    $nextToken: String
    $siteName: String!
    $limit: Int
    $filter: ModelDigitalLabLogbookInstrumentFilterInput
  ) {
    instrumentBySiteAndSerialNumber(
      serialNumber: { beginsWith: $serialNumber }
      nextToken: $nextToken
      siteName: $siteName
      limit: $limit
      filter: $filter
    ) {
      items {
        addInfo {
          currentGxPStatusFromLogs
          currentSystemStatusFromLogs
          id
          inventoryId
        }
        belongingToGroup
        buildingLocation {
          key
          value
        }
        entryType
        cluster
        comment
        configurationBaseline
        costCenter
        createdAt
        createdBy
        csv
        datamodelVersion
        dateOfLastMaintanance
        dateOfNextMaintanance
        dateOfNextPeriodicReview
        displayImage
        electronicRecord
        electronicSignatures
        equipmentAdministrator
        equipmentCategory
        equipmentId
        equipmentModel
        equipmentNickName
        floor {
          key
          value
        }
        functionalLocation
        gxpRelevant
        installedTests {
          name
          version
        }
        instrumentGTIN
        instrumentRUDI
        inventoryId
        inventoryNumber
        isBookable
        isVisualized
        linkedInstance
        location
        maintenancePlan
        manufacturer
        materialNumber
        modelName
        qualificationStatus
        responsiblePerson
        responsibleProxy
        room {
          key
          value
        }
        secondaryID
        secondaryIDMapping
        serialNumber
        shortIdentifier
        siteId
        siteName
        siteTimezone
        softwareVersion
        sop {
          key
          value
        }
        status
        systemOwner
        systemStatus
        updatedAt
        updatedBy
        variant
      }
      nextToken
    }
  }
`;

export const LIST_BACKEND_HEALTH_CHECKS = gql`
  query listBackendHealthChecks {
    listBackendHealthChecks {
      items {
        ciJobId
        commitHash
        createdAt
        id
        moduleName
        moduleVersion
        enableAnalyticsTracking
        updatedAt
        isAppUnderMaintenance
      }
    }
  }
`;

export const VALIDATE_ELN_ID = gql`
  query getElnIdValidation($eLNid: String!) {
    getElnIdValidation(eLNid: $eLNid) {
      message
      statusCode
      elnAttributes {
        elnKey
        experimentId
        guid
        isValid
      }
    }
  }
`;
export const GET_DIGITAL_LAB_LOGBOOK_INSTRUMENT = gql`
  query getDigitalLabLogbookInstrument($inventoryId: String!) {
    getDigitalLabLogbookInstrument(inventoryId: $inventoryId) {
      belongingToGroup
      cluster
      clusterId
      comment
      configurationBaseline
      configurationDetails
      costCenter
      createdAt
      createdBy
      csv
      datamodelVersion
      addInfo {
        currentGxPStatusFromLogs
        currentSystemStatusFromLogs
        id
        inventoryId
      }
      buildingLocation {
        key
        value
      }
      dateOfLastMaintanance
      dateOfNextMaintanance
      dateOfNextPeriodicReview
      displayImage
      electronicRecord
      electronicSignatures
      equipmentAdministrator
      equipmentCategory
      equipmentId
      equipmentModel
      entryType
      equipmentNickName
      floor {
        key
        value
      }
      functionalLocation
      gxpRelevant
      installedTests {
        name
        version
      }
      instrumentGTIN
      instrumentRUDI
      inventoryId
      inventoryNumber
      isBookable
      isVisualized
      linkedInstance
      location
      maintenancePlan
      manufacturer
      materialNumber
      modelName
      qualificationDocuments {
        documentId
        name
      }
      qualificationStatus
      responsiblePerson
      responsibleProxy
      room {
        key
        value
      }
      secondaryID
      secondaryIDMapping
      serialNumber
      shortIdentifier
      siteId
      siteName
      siteTimezone
      softwareVersion
      sop {
        key
        value
      }
      status
      systemOwner
      systemStatus
      updatedAt
      updatedBy
      variant
    }
  }
`;

export const LIST_DIGITAL_LAB_LOGBOOK_SUBEQUIPMENT_INSTRUMENT = gql`
  query listDigitalLabLogbookInstruments(
    $filter: ModelDigitalLabLogbookInstrumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDigitalLabLogbookInstruments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        inventoryId
        belongingToGroup
        cluster
        clusterId
        equipmentId
        qualificationStatus
        systemStatus
        equipmentModel
        equipmentNickName
        gxpRelevant
        linkedInstance
        location
        manufacturer
        materialNumber
        modelName
        responsiblePerson
        responsibleProxy
        serialNumber
        shortIdentifier
        siteName
        softwareVersion
        status
        variant
        entryType
        equipmentCategory
        instrumentGTIN
        instrumentRUDI
        secondaryID
        createdAt
        createdBy
        addInfo {
          id
          inventoryId
          currentSystemStatusFromLogs
          currentGxPStatusFromLogs
        }
      }
      nextToken
    }
  }
`;
export const LOGBOOK_GET_LOGGED_USER_DETAILS = gql`
  query getLoggedInUserDetails {
    getLoggedInUserDetails(limit: 1) {
      items {
        id
        email
        lastFilter
        familyName
        givenName
        name
        phone
        status
        site
        user
        lastPageSize
        lastDisplayColumns
        lastFilter
        userFilters {
          items {
            filterName
            filterDefinition
          }
        }
      }
    }
  }
`;

export const LIST_INSTRUMENT_REPOSITORY_AUDITS = gql`
  query listInstrumentRepositoryAudits(
    $inventoryId: String
    $dateRange: [String]
    $filter: ModelInstrumentRepositoryAuditFilterInput
    $nextToken: String
  ) {
    listInstrumentRepositoryAudits(
      inventoryId: $inventoryId
      modifiedDate: { between: $dateRange }
      sortDirection: DESC
      filter: $filter
      nextToken: $nextToken
    ) {
      items {
        action
        changes
        createdAt
        editReason
        editComment
        equipmentCategory
        equipmentId
        equipmentModel
        equipmentNickName
        inventoryId
        linkedInstanceRef {
          isActive
          linkedInstance
          secondaryIdMapping
        }
        location
        manufacturer
        materialNumber
        modifiedByEmail
        modifiedByUserId
        modifiedDate
        newData
        oldData
        secondaryId
        secondaryIdMapping
        serialNumber
        updatedAt
        variant
        belongingToGroup
        responsiblePerson
        responsibleProxy
        siteName
      }
      nextToken
    }
  }
`;
export const LOG_SHEET_BY_CLUSTER_ENTRY_REF_ID = gql`
  query logSheetByClusterEntryRefId($clusterEntryRefId: String, $limit: Int, $nextToken: String) {
    logSheetByClusterEntryRefId(clusterEntryRefId: $clusterEntryRefId, nextToken: $nextToken, limit: $limit) {
      items {
        inventoryId
        logSheetEntryId
        gxpReady {
          key
          value
        }
        systemStatus {
          key
          value
        }
      }
      nextToken
    }
  }
`;

export const RUN_LOG_BY_CLUSTER_ENTRY_REF_ID = gql`
  query runLogByClusterEntryRefId($clusterEntryRefId: String, $limit: Int, $nextToken: String) {
    runLogByClusterEntryRefId(clusterEntryRefId: $clusterEntryRefId, nextToken: $nextToken, limit: $limit) {
      items {
        runLogEntryId
        inventoryId
      }
    }
  }
`;

export const GET_EQUIPMENT_OR_CLUSTER_SNAPSHOT = gql`
  query getEquipmentOrClusterSnapshot($inventoryId: String!, $snapshotDateTime: AWSDateTime!) {
    getEquipmentOrClusterSnapshot(inventoryId: $inventoryId, snapshotDateTime: $snapshotDateTime) {
      id
      belongingToGroup
      equipmentId
      serialNumber
      materialNumber
      modelName
      equipmentNickName
      equipmentCategory
      equipmentModel
      manufacturer
      variant
      clusterId
      inventoryId: id
      location
      responsiblePerson
      responsibleProxy
      softwareVersion
      siteName
      configurationBaseline
      dateOfNextMaintanance
      softwareVersion
      gxpRelevant
      entryType
      systemStatus
      qualificationStatus
      status
      addInfo {
        currentGxPStatusFromLogs
        currentSystemStatusFromLogs
      }
      subEquipment {
        id
        belongingToGroup
        buildingLocation {
          key
          value
        }
        cluster
        clusterId
        dateOfLastMaintanance
        dateOfNextMaintanance
        dateOfNextPeriodicReview
        documentedMaintenanceDate
        entryType
        equipmentCategory
        equipmentId
        equipmentModel
        equipmentNickName
        floor {
          key
          value
        }
        gxpRelevant
        inventoryNumber
        inventoryId: id
        location
        maintenancePlan
        manufacturer
        materialNumber
        modelName
        positionInCluster
        responsiblePerson
        responsibleProxy
        room {
          key
          value
        }
        serialNumber
        siteId
        siteName
        siteTimezone
        softwareVersion
        systemStatus
        variant
        addInfo {
          currentGxPStatusFromLogs
          currentSystemStatusFromLogs
        }
      }
    }
  }
`;
export const LIST_DIGITAL_LAB_LOGBOOK_REASON = gql`
  query configurationByModelAndText {
    configurationByModelAndText(modelName: "EDITREASON") {
      items {
        configText
        id
        modelName
        createdDate
      }
    }
  }
`;

export const LIST_USER_DISPLAY_COLUMNS = gql`
  query listUserDisplays($userID: ID!) {
    listUserDisplays(userID: $userID) {
      items {
        displayDefinition
        displayName
        id
        userID
      }
    }
  }
`;

export const LIST_SYSTEM_DISPLAY_COLUMNS = gql`
  query listSystemDisplays {
    listSystemDisplays {
      items {
        displayDefinition
        displayName
        id
      }
    }
  }
`;

export const GET_LOGBOOK_RUNLOG = gql`
  query getDigitalLabLogbookRunLog($id: ID!) {
    getDigitalLabLogbookRunLog(id: $id) {
      equipmentId
      serialNumber
      equipmentModel
      gxpReady {
        key
        value
      }
      systemStatus {
        key
        value
      }
      equipmentDetails {
        variant
        equipmentNickName
        inventoryId
        addInfo {
          currentGxPStatusFromLogs
          currentSystemStatusFromLogs
        }
      }
    }
  }
`;

export const GET_LOGBOOK_ACTIONLOG = gql`
  query getDigitalLabLogbookLogSheet($id: ID!) {
    getDigitalLabLogbookLogSheet(id: $id) {
      equipmentId
      serialNumber
      equipmentModel
      gxpReady {
        key
        value
      }
      systemStatus {
        key
        value
      }
      equipmentDetails {
        inventoryId
        equipmentNickName
        variant
      }
    }
  }
`;

///////////// Repo Graphql /////////////////////////////
export const REPO_GET_DIGITAL_LAB_INSTRUMENT_REPOSITORY_ENTRY = gql`
  query GetDigitalLabInstrumentRepositoryEntry($id: ID!) {
    getDigitalLabInstrumentRepositoryEntry(id: $id) {
      subEquipment {
        items {
          id
          cluster
          clusterId
          positionInCluster
        }
      }
    }
  }
`;
export const LIST_IR_CATEGORY_MAPPINGS_MASTER = gql`
  query listIRCategoryMappings($limit: Int, $nextToken: String) {
    listIRCategoryMappings(limit: $limit, nextToken: $nextToken, filter: { isActive: { eq: true } }) {
      nextToken
      items {
        displayImage
        equipmentCategory
        equipmentModel
        variant
        isActive
        manufacturer
        modelName
      }
    }
  }
`;
export const LIST_IR_CLUSTER_CATEGORY_MAPPINGS_MASTER = gql`
  query listIRClusterCategoryMappings($limit: Int, $nextToken: String) {
    listIRClusterCategoryMappings(limit: $limit, nextToken: $nextToken, filter: { isActive: { eq: true } }) {
      nextToken
      items {
        equipmentCategory: clusterCategory
        equipmentModel: clusterModel
        variant
        isActive
        variant
        createdAt
        modifiedById
        modelName
      }
    }
  }
`;
export const LIST_IR_LINKED_INSTANCE_MASTER = gql`
  query listSupportedLinkedInstances($limit: Int, $nextToken: String) {
    listSupportedLinkedInstances(limit: $limit, nextToken: $nextToken, filter: { isActive: { eq: true } }) {
      nextToken
      items {
        linkedInstance
        linkedInstanceDisplay
        secondaryIdMapping
      }
    }
  }
`;
export const LIST_IR_GROUP_MASTER = gql`
  query listIRGroupMasters($limit: Int, $nextToken: String) {
    listIRGroupMasters(limit: $limit, nextToken: $nextToken) {
      nextToken
      items {
        value
      }
    }
  }
`;
export const LIST_IR_FILTER_LOOKUPS_MASTER = gql`
  query listIRFilterLookups($limit: Int, $nextToken: String, $field: String) {
    listIRFilterLookups(limit: $limit, nextToken: $nextToken, filterAttribute: $field, sortDirection: ASC) {
      nextToken
      items {
        filterValue
      }
    }
  }
`;
export const LIST_IR_SOP_MASTER = gql`
  query listIRSOPMasters($limit: Int, $nextToken: String) {
    listIRSOPMasters(limit: $limit, nextToken: $nextToken, filter: { isActive: { eq: true } }) {
      nextToken
      items {
        isActive
        key
        value
      }
    }
  }
`;

export const GET_EXPORT_FILE_TO_ENDOR = gql`
  query getExportFileToEndor(
    $actionType: String
    $targetModule: String
    $s3id: String
    $fileDescription: String
    $checksum: String
    $metadata: String
  ) {
    getExportFileToEndor(
      actionType: $actionType
      targetModule: $targetModule
      s3id: $s3id
      fileDescription: $fileDescription
      checksum: $checksum
      metadata: $metadata
    ) {
      StatusCode
      url
      s3FileName
      error
      data {
        createdAt
        addedBy
        status
        fileName
        fileDescription
        checksum {
          type
          value
        }
        targetModule
        fileId
        groupId
        bucketRegion
        groupMetadataVersion
      }
    }
  }
`;

export const GET_IMPORT_FILE_FROM_ENDOR = gql`
  query getImportFileFromEndor($targetModule: String, $fileId: String, $metadata: String) {
    getImportFileFromEndor(targetModule: $targetModule, fileId: $fileId, metadata: $metadata) {
      StatusCode
      url
      error
    }
  }
`;

export const GET_CHECKLIST_FOR_EQUIPMENT = gql`
  query getChecklistsForEquipment($input: GetChecklistsForEquipmentInput) {
    getChecklistsForEquipment(input: $input) {
      items {
        url
        description
      }
    }
  }
`;

///////////// Repo Graphql /////////////////////////////

///////////// LP Graphql /////////////////////////////
export const LP_GET_LOGGED_USER_DETAILS_USER_FILTERS = gql`
  query getLoggedInUserDetails {
    getLoggedInUserDetails(limit: 1) {
      items {
        id
        name
        email
        userFilters {
          items {
            filterName
            filterDefinition
          }
        }
      }
    }
  }
`;
///////////// LP Graphql /////////////////////////////

///////////// Booking Graphql /////////////////////////////
export const GET_LIST_OF_ACTION_TYPES = gql`
  query listActionTypes {
    __type(name: "ActionTypes") {
      enumValues {
        name
      }
    }
  }
`;
///////////// Booking Graphql /////////////////////////////
