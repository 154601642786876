//@ts-nocheck
import { OwcCard, OwcTypography, OwcIcon } from "@one/react";
import { Notify } from "@digitallab/grid-common-components";
import { generateID } from "@digitallab/grid-common-components";
import { useState } from "react";
import { useFormikContext } from "formik";
import { ConfirmDialog } from "../../../../components/shared";
import ActionLogLink from "../../ActionLogLink";
import BinIcon from "../../../../icons/BinIcon";
import EditIcon from "../../../../icons/EditIcon";

type LinkActionsType = "Add" | "Edit" | "Delete";

export const LinksList = () => {
  const { values, setFieldValue } = useFormikContext();
  const [actionLogLinkCurrentFlag, setActionLogLinkCurrentFlag] = useState<LinkActionsType | undefined>();
  const [actionLogLinkName, setActionLogLinkName] = useState();
  const [actionLogLinkUrl, setActionLogLinkUrl] = useState();
  const [actionLogLinkCurrentIndex, setActionLogLinkCurrentIndex] = useState();
  const [addLinkFlag, setAddLinkFlag] = useState();

  const handleActionLinkChange = () => {
    let currentExternalDocment = values.externalDocument || [];
    switch (actionLogLinkCurrentFlag) {
      case "Add":
        currentExternalDocment.push({
          link: actionLogLinkUrl,
          name: actionLogLinkName
        });
        break;
      case "Edit":
        currentExternalDocment[actionLogLinkCurrentIndex] = {
          link: actionLogLinkUrl,
          name: actionLogLinkName
        };
        break;
      case "Delete":
        currentExternalDocment = values.externalDocument?.filter((_, index) => index !== actionLogLinkCurrentIndex);
        break;
    }

    setFieldValue("externalDocument", currentExternalDocment, true);
    setActionLogLinkName(null);
    setActionLogLinkUrl(null);
    setAddLinkFlag(false);
    Notify({
      type: "success",
      icon: "circle_confirm",
      title: "",
      text: `Link ${
        actionLogLinkCurrentFlag === "Delete" ? "deleted" : actionLogLinkCurrentFlag?.toLocaleLowerCase() + "ed"
      } successfully`
    });
    setActionLogLinkCurrentFlag(undefined);
  };

  const openLinkPopup = (action: "Add" | "Edit" | "Delete") => {
    setActionLogLinkCurrentFlag(action);
  };

  return (
    <>
      <OwcCard
        flat
        style={{
          padding: "0px",
          width: "100%",
          border: "1px solid lightgray",
          boxShadow: "none",
        }}
      >
        <div
          slot="header"
          style={{
            display: "flex",
            background: "var(--one-color-background-neutral-2)",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "10px"
          }}
        >
          <OwcTypography
            variant="button"
            id={generateID.customID("AddLinkHeadingBanner", `addLinkHeadingBanner`, "type_title")}
          >
            <strong>Link list</strong>
          </OwcTypography>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer"
            }}
          >
            <OwcIcon
              type="legacy"
              variant="primary"
              name="circle_add"
              onClick={() => {
                openLinkPopup("Add");
              }}
            />
            <OwcTypography
              variant="subtitle2"
              style={{
                marginLeft: "5px",
                color: "var(--one-color-interaction-default-brand-1)"
              }}
              onClick={() => {
                openLinkPopup("Add");
              }}
            >
              <strong>Add link</strong>
            </OwcTypography>
          </div>
        </div>

        {(values.externalDocument || values.externalDocument?.length > 0) && (
          <div
            style={{
              boxSizing: "border-box",
              height: "200px",
              width: "100%",
              marginTop: 0,
              marginBottom: 0
            }}
          >
            {values.externalDocument?.map((item, index) => {
              return (
                <div
                  key={`${item.name}${index}`}
                  style={{
                    boxSizing: "border-box",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    borderBottom: "1px solid #C2BAB5AA",
                    height: "20%",
                  }}
                >
                  <div style={{ padding: "0 12px 0 16px" }}>
                    <OwcIcon type="outlined" name="link"/>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      fontSize: "14px"
                    }}
                  >
                    <div
                      style={{
                        fontSize: "16px",
                        color: "var(--one-color-interaction-default-brand-1)"
                      }}
                    >
                      {item.name}
                    </div>
                  </div>
                  <div
                    style={{ padding: "2px 8px 0 0", cursor: "pointer" }}
                    onClick={() => {
                      setActionLogLinkName(item.name);
                      setActionLogLinkUrl(item.link);
                      setActionLogLinkCurrentIndex(index);
                      setAddLinkFlag(false);
                      openLinkPopup("Edit");
                    }}
                  >
                    <EditIcon />
                  </div>
                  <div
                    style={{ padding: "0 32px 0 16px", cursor: "pointer" }}
                    onClick={() => {
                      openLinkPopup("Delete");
                      setAddLinkFlag(true);
                      setActionLogLinkCurrentIndex(index);
                    }}
                  >
                    <BinIcon />
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </OwcCard>

      <ConfirmDialog
        close={() => setActionLogLinkCurrentFlag(undefined)}
        approveText={actionLogLinkCurrentFlag === "Edit" ? "Save" : actionLogLinkCurrentFlag}
        open={!!actionLogLinkCurrentFlag}
        onApprove={handleActionLinkChange}
        onCancel={() => {
          setActionLogLinkName(null);
          setActionLogLinkUrl(null);
          setActionLogLinkCurrentFlag(undefined);
          setActionLogLinkCurrentIndex(null);
        }}
        title={`${actionLogLinkCurrentFlag} link`}
        isEnabled={addLinkFlag}
        content={
          <ActionLogLink
            actionLogLinkName={actionLogLinkName}
            setActionLogLinkName={setActionLogLinkName}
            actionLogLinkUrl={actionLogLinkUrl}
            setActionLogLinkUrl={setActionLogLinkUrl}
            actionLogLinkCurrentFlag={actionLogLinkCurrentFlag}
            setAddLinkFlag={setAddLinkFlag}
          />
        }
        disableBackdropClick={true}
      />
    </>
  );
};
