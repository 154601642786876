import { useContext } from "react";
import styled, { css } from "styled-components";
import { DetailsCellStyled } from "../../../components/shared/details-cells/DetailsCellStyled";
import COMMON_LOGS_DATA_MODEL from "../../../utils/constants/commonLogsDataModel.js";
import LOGBOOK_LABEL from "../../../utils/constants/logbookLabel";
import { valueOrEmpty } from "../../../utils/helpers/text";
import { InstrumentInformationsContext } from "../instrument-informations-dialog/instrument-informations-context/context";
import { ItemDialogFormContext } from "../log-book-item-form-dialog/item-context/context";
import ValueOrFallbackText from "./ValueOrFallbackText";
import { entryType as entryTypeData } from "../../../constants";
import { OwcChip } from "@one/react";
import ClusterLogBookSubComponentActions from "../log-book-table/ClusterLogBookSubComponentActions";
export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const ClickableCell = styled.span`
  :hover {
    color: #0066cc;
  }
  cursor: pointer;
`;

export const CustomDetailsCellStyled = styled(DetailsCellStyled)`
  ${(props) =>
    props.expand &&
    css`
      &&& {
        width: auto;
      }

      & > :last-child {
        text-overflow: unset;
        overflow: unset;
        white-space: unset;
        word-break: unset;
      }
    `}
`;

export const RunLogWrapper = styled.div`
  ${DetailsCellStyled} {
    display: inline-block;
    margin-right: 40px;
    margin-bottom: 20px;
    width: 170px;
  }
`;

export const EquipmentIdColumn = {
  equipmentId: {
    text: COMMON_LOGS_DATA_MODEL.equipmentId.value,
    component: ({ item }) => valueOrEmpty(item?.equipmentId, false, "-"),
    headProps: {
      style: {
        flex: "0 1 12%"
      }
    },
    cellProps: {
      style: {
        flex: "0 1 12%"
      }
    }
  }
};

export const SerialNumberColumn = {
  serialNumber: {
    text: COMMON_LOGS_DATA_MODEL.serialNumber.value,
    component: ({ item }) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const { handleOpenInstrumentInformation } = useContext(InstrumentInformationsContext);

      return (
        <ClickableCell onClick={() => handleOpenInstrumentInformation(item)}>
          {valueOrEmpty(item?.serialNumber, false, "-")}
        </ClickableCell>
      );
    },
    headProps: {
      style: {
        flex: "0 1 13%"
      }
    },
    cellProps: {
      style: {
        flex: "0 1 13%"
      }
    }
  }
};

export const InstrumentModelColumn = {
  equipmentModel: {
    text: COMMON_LOGS_DATA_MODEL.equipmentModel.value,
    component: ({ item }) => valueOrEmpty(item?.equipmentModel, false, "-"),
    headProps: {
      style: {
        flex: "0 1 15%"
      }
    },
    cellProps: {
      style: {
        flex: "0 1 15%"
      }
    }
  }
};

export const EquipmentNickNameColumn = {
  equipmentNickName: {
    text: COMMON_LOGS_DATA_MODEL.equipmentNickName.value,
    component: ({ item }) => valueOrEmpty(item?.instrumentDetails?.equipmentNickName, false, "-"),
    headProps: {
      style: {
        flex: "0 1 15%"
      }
    },
    cellProps: {
      style: {
        flex: "0 1 15%"
      }
    }
  }
};

export const ApproverUserName = {
  approverUserName: {
    text: COMMON_LOGS_DATA_MODEL.approverUserName.value,
    headProps: {
      style: {
        flex: "0 1 15%"
      }
    },
    cellProps: {
      style: {
        flex: "0 1 15%"
      }
    }
  }
};

export const VariantColumn = {
  variant: {
    text: COMMON_LOGS_DATA_MODEL.variant.value,
    headProps: {
      style: {
        flex: "0 1 15%"
      }
    },
    cellProps: {
      style: {
        flex: "0 1 15%"
      }
    }
  }
};

/**
 * Function to convert any string to simple, only alphanumerical string.
 * @param string String to convert
 */
export const toSimpleString = (string) => {
  return string?.toLowerCase?.()?.replaceAll?.(/[\W_]/g, "");
}

/**
 * Function to compare values of action type from booking and log book.
 * @param {*} logBookValue option from log book values
 * @param {*} bookingValue option from booking values
 * @return {boolean}
 */
export const actionTypeValuesComparator = (logBookValue, bookingValue) => {
  return (
    toSimpleString(logBookValue.key)
    ===
    toSimpleString( bookingValue.key )
  ) ||
  (
    toSimpleString(logBookValue.key)
    ===
    toSimpleString(bookingValue.key.split("_").slice(1).join())
  )
};

export const ActionTypeCell = ({ item }) => (
  <ValueOrFallbackText
    reduxPath={["runLogsForm", "actionLog"]}
    comparator={actionTypeValuesComparator}
    item={item.action}
    defaultValue="-"
  />
);

export const GxpReadyCell = ({ item }) => {
  return (
    <>
      <ValueOrFallbackText
        reduxPath={["runLogsForm", "gxpReadys"]}
        item={
          entryTypeData.cluster === item?.equipmentDetails?.entryType ?
            item.gxpReadyCluster :
            (item.gxpReady.newValue || item.gxpReady)
        }
        defaultValue="-"
      />
    </>
  );
};

export const SystemStatusCell = ({ item, isAuditPage = false }) => {
  const withChip = (
    <OwcChip outlined>
      <ValueOrFallbackText
        reduxPath={["runLogsForm", "systemStatuses"]}
        item={
          entryTypeData.cluster === item.equipmentDetails?.entryType
            ? item.systemStatusCluster || "-"
            : item.systemStatus?.newValue || item.systemStatus || "-"
        }
        defaultValue="-"
      />
    </OwcChip>
  );

  const withOutChip = (
    <ValueOrFallbackText
      reduxPath={["runLogsForm", "systemStatuses"]}
      item={
        entryTypeData.cluster === item?.equipmentDetails?.entryType
          ? item?.systemStatusCluster || "-"
          : item?.systemStatus?.newValue || item?.systemStatus || "-"
      }
      defaultValue="-"
    />
  );

  return <>{!isAuditPage ? <>{withChip}</> : <>{withOutChip}</>}</>;
};

export const GxpReadyAllCell = ({ item }) => {
  const textValue = valueOrEmpty(
    entryTypeData.cluster === item?.equipmentDetails?.entryType ?
      item?.gxpReadyCluster?.value :
      (item?.gxpReady?.newValue?.value || item?.gxpReady?.value),
    false,
    "-"
  );
  return textValue === "-" ? "-" : <OwcChip outlined>{textValue}</OwcChip>;
};

export const SystemStatusAllCell = ({ item }) => {
  const textValue = valueOrEmpty(
    entryTypeData.cluster === item?.equipmentDetails?.entryType
      ? item?.systemStatusCluster?.value
      : (item?.systemStatus?.newValue?.value || item?.systemStatus?.value),
    false,
    "-"
  );
  return textValue === "-" ? "-" : <OwcChip outlined>{textValue}</OwcChip>;
};

export const MediaTypeCell = ({ item }) => (
  <ValueOrFallbackText
    reduxPath={["runLogsForm", "mediaTypes"]}
    item={item.mediaType}
    defaultValue="-"
    isArrayOfObjects={true}
  />
);

export const InstrumentModelClusterColumn = {
  equipmentModel: {
    text: COMMON_LOGS_DATA_MODEL.equipmentModel.value,
    component: ({ item }) => valueOrEmpty(item?.equipmentModel, false, "-"),
    headProps: {
      style: {
        flex: "0 1 19%"
      }
    },
    cellProps: {
      style: {
        flex: "0 1 19%"
      }
    }
  }
};

export const EquipmentNickNameClusterColumn = {
  equipmentNickName: {
    text: COMMON_LOGS_DATA_MODEL.equipmentNickName.value,
    component: ({ item }) => valueOrEmpty(item?.instrumentDetails?.equipmentNickName, false, "-"),
    headProps: {
      style: {
        flex: "0 1 16%"
      }
    },
    cellProps: {
      style: {
        flex: "0 1 16%"
      }
    }
  }
};

export const ApproverUserNameCluster = {
  approverUserName: {
    text: COMMON_LOGS_DATA_MODEL.approverUserName.value,
    headProps: {
      style: {
        flex: "0 1 17%"
      }
    },
    cellProps: {
      style: {
        flex: "0 1 17%"
      }
    }
  }
};

export const InstrumentClusterSubComponentColumn = (dataTestId) => ({
  subEquipment: {
    component: ({ item }) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const { setSubComponentOpen } = useContext(ItemDialogFormContext);
      return (
        <ClusterLogBookSubComponentActions
          dataTestId={dataTestId}
          item={item}
          setSubComponentOpen={setSubComponentOpen}
        />
      );
    },
    text: LOGBOOK_LABEL.sub_components,
    headProps: {
      style: {
        flex: "0 0 132px"
      }
    },
    cellProps: {
      style: {
        flex: "0 0 132px"
      }
    }
  }
});
