import { DateTimeDetailValue } from "../../../../components/shared/details-cells/DateTimeDetailValue";
import ACTION_LOGS_DATA_MODEL from "../../../../utils/constants/actionLogsDataModel";
import {
  ApproverCell,
  DescriptionCell,
  OperatorCell,
  ExternalDocument
} from "../../common-for-log-sheet-and-run-logs/HistoryCommonCellsInfo";
import {
  ActionTypeCell,
  GxpReadyCell,
  SystemStatusCell
} from "../../common-for-log-sheet-and-run-logs/table-common-meta-info";
import { changeDateFormat } from "../../helpers";
import { customItem } from "../log-sheet-table/customItem";

export const HistoryLogSheetCellsInfoArray = [
  {
    label: ACTION_LOGS_DATA_MODEL.actionType.value,
    component: ActionTypeCell,
    props: {
      fullRow: false
    }
  },
  {
    label: ACTION_LOGS_DATA_MODEL.actionDate.value,
    component: ({ item }) => <DateTimeDetailValue date={changeDateFormat(item?.actionDate)} time={item.actionTime} />
  },
  DescriptionCell,
  {
    label: ACTION_LOGS_DATA_MODEL.systemStatus.value,
    component: ({ item }) => {
      return (
        <>
          <SystemStatusCell item={item} isAuditPage={true} />
        </>
      );
    },
    props: {
      fullRow: false
    }
  },
  {
    label: ACTION_LOGS_DATA_MODEL.gxpReady.value,
    component: GxpReadyCell,
    props: {
      fullRow: false
    }
  },
  {
    label: ACTION_LOGS_DATA_MODEL.ecrNumber.value,
    field: ["ecrNumber"],
    props: {
      fullRow: false
    }
  },
  ApproverCell,
  OperatorCell,
  customItem({
    dataModel: ACTION_LOGS_DATA_MODEL.softwareVerison,
    valueKey: "newValue",
    whiteSpace: false
  }),
  customItem({
    dataModel: ACTION_LOGS_DATA_MODEL.configurationDetails,
    valueKey: "newValue",
    whiteSpace: true,
    width: "100%"
  }),
  ExternalDocument
];
