import styled from "styled-components";

const MainBoxBase = styled.div`
  display: grid;
  grid-gap: 20px 12px;
`;
export const Mainbox = styled(MainBoxBase)`
  grid-template-columns: repeat(4, 1fr);
`;
export const ThreeFourthsCell = styled.div`
  grid-column: 1 / 5;
`;
export const FirstHalfCell = styled.div`
  grid-column: 1 / 2;
  margin: 20px 0;
`;
export const PreviousButtonWrapper = styled.div`
  display: flex;
  flex-grow: 1;
`;
export const LabelInnerHeading = styled.span`
  font-weight: 500;
  font-size: 16px;
  display: flex;
  align-items: center;
`;
export const TwoColumnsBox = styled(MainBoxBase)`
  grid-template-columns: 1fr 1fr;
  margin-bottom: 20px;
`;
export const ExpandRow = styled.div`
  grid-column: 1 / -1;
`;
export const MarginDiv = styled.div`
  margin: 10px;
  align-items: center;
  display: flex;
`;
export const Title = styled(ExpandRow)`
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  margin-bottom: -8px;
`;
export const TitleAdjusted = styled(ExpandRow)`
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
`;
export const ChipsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
export const LinkContainerDivView = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  padding: 10px 0;
  border: 1px solid var(--one-color-background-contrast-1);
`;
export const LinkContainerDivInnerLeft = styled.div`
  justify-content: flex-start;
  flex-direction: row;
  display: inline-flex;
  margin-left: 20px;
  width: calc(60% - 20px);
`;
export const LinkContainerDivInnerRight = styled.div`
  justify-content: ${(props) => props.isLinkTable ? 'flex-end' : 'flex-start'};
  flex-direction: row;
  display: inline-flex;
  margin-right: 20px;
  width: calc(40% - 36px);
`;
export const LinkContainerStyled = styled.div`
  display: block;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  overflow-x: hidden;
  margin: 0;
`;
