import { EN_DASH } from "@digitallab/grid-common-components";

export const customItem = ({
  dataModel,
  valueKey,
  whiteSpace,
  width
}: {
  dataModel: { key: string; value: string };
  valueKey: string;
  whiteSpace: boolean;
  width?: string;
}) => {
  const isWhiteSpace = whiteSpace ? { whiteSpace: "pre-wrap" } : undefined;
  return {
    label: dataModel.value,
    field: dataModel.key,
    props: {
      expand: whiteSpace,
      style: { width: width || undefined }
    },
    component: ({ item }: { item: { [key: string]: { [key: string]: string } } }) => (
      <div style={isWhiteSpace}>{item?.[dataModel.key]?.[valueKey] || EN_DASH}</div>
    )
  };
};
