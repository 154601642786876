import { DigitalLabLogbookInstrumentModelType } from "../../../../models/DigitalLabLogbookInstrumentModelType";
import { ValueAttributeModelType } from "../../../../models/ValueAttributeModelType";
import * as types from "./actionTypes";

export const loadMediaType = (mediaTypes: ValueAttributeModelType[]) => ({
  type: types.LOAD_MEDIA_TYPES,
  payload: mediaTypes
});
export const loadTips = (tips: ValueAttributeModelType[]) => ({
  type: types.LOAD_TIPS,
  payload: tips
});

export const loadGxpReadys = (gxpReadys: ValueAttributeModelType[]) => ({
  type: types.LOAD_GXP_READYS,
  payload: gxpReadys
});
export const loadSystemStatuss = (systemStatuses: ValueAttributeModelType[]) => ({
  type: types.LOAD_SYSTEM_STATUSS,
  payload: systemStatuses
});
export const loadLogSheetActions = (actionLog: ValueAttributeModelType[]) => ({
  type: types.LOAD_LOG_SHEET_ACTIONS,
  payload: actionLog
});
export const loadInstrumentSuggestions = (instrumentSuggestions: DigitalLabLogbookInstrumentModelType[]) => ({
  type: types.LOAD_INSTRUMENT_SUGGESTIONS,
  payload: instrumentSuggestions
});
export const loadInstrumentSuggestion = (instrumentSuggestion: DigitalLabLogbookInstrumentModelType) => ({
  type: types.LOAD_INSTRUMENT_SUGGESTION,
  payload: instrumentSuggestion
});
export const loadMegaClusterIds = (megaClusterIds: string[]) => ({
  type: types.LOAD_MEGA_CLUSTER_IDS,
  payload: megaClusterIds
});
