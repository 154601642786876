export const findOrFallbackByObject = (
  array,
  obj,
  { propLabel = "value", propValue = "key", defaultValue = "", comparator } = {}
) => {
  const theItem = array.find((item) => {
    if (comparator) {
      return comparator(item, obj);
    }
    return item[propValue] === obj?.[propValue];
  });

  return theItem?.[propLabel] ?? obj?.[propLabel] ?? defaultValue;
};
