import { FormikProps } from "formik";
import { useSelector } from "react-redux";
import { RootState } from "../../../../reducers";
import { KEEP_STATUS_VALUE } from "../../../../logs/StepperContainer";
import ACTION_LOGS_DATA_MODEL from "../../../../utils/constants/actionLogsDataModel";
import { OwcListItem, OwcSelectDropdown, OwcSlideToggle } from "@one/react";
export const SystemStatusSelectWithSwitch = ({
  formik,
  editMode,
  isPropagationDisabled,
  numberOfSelectedEquipment
}: {
  formik: FormikProps<{
    systemStatus: { value: string; key: string; newValue: {value: string; key: string;}; shouldBePublished: boolean };
  }>;
  editMode: boolean;
  isPropagationDisabled: boolean;
  numberOfSelectedEquipment: number;
}) => {
  const systemStatuses = useSelector(
    (store: RootState) => store.runLogsForm.systemStatuses
  ) as {key: string, value: string}[];

  const options = (numberOfSelectedEquipment > 1 ?
      [KEEP_STATUS_VALUE].concat(systemStatuses) :
      systemStatuses
  ).map(({ key, value }) => ({
    key,
    value,
    label: value,
  })) as {key: string, value: string, label: string}[];

  return (
    <>
      <OwcSelectDropdown
        style={{ width: "100%" }}
        label={`${ACTION_LOGS_DATA_MODEL.systemStatus.value} *`}
        required
        onSelectChange={(event: CustomEvent) =>
          formik.setFieldValue(
            "systemStatus.newValue",
            {
              key: options[event.detail[0]].key,
              value: options[event.detail[0]].value,
            }
          )
        }
      >
        {
          options.map((option) => {
            return <OwcListItem
              key={option.key}
              selected={
                option.key === (
                  formik.values.systemStatus?.newValue?.key ||
                  formik.values.systemStatus?.key
                )
              }
            >
              {option.label}
            </OwcListItem>
          })
        }
      </OwcSelectDropdown>
      <OwcSlideToggle
        checked={formik.values.systemStatus?.shouldBePublished}
        onInputChange={(event: { detail: string }) =>
          formik.setFieldValue("systemStatus.shouldBePublished", event.detail)
        }
        id="systemStatus.shouldBePublished"
        disabled={editMode || isPropagationDisabled}
      >
        {`Publish “${ACTION_LOGS_DATA_MODEL.systemStatus.value}” to Equipment Repository`}
      </OwcSlideToggle>
    </>
  );
};
