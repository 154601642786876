const EditIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16 18L2 18C0.9 18 0 17.1 0 16L0 2C0 0.9 0.9 0 2 0L10 0V2L2 2L2 16L16 16L16 8H18L18 16C18 17.1 17.1 18 16 18Z" fill="#706B69"/>
      <path d="M17.4 1.8L16.2 0.6C15.8 0.2 15.3 0 14.8 0C14.3 0 13.8 0.2 13.4 0.6L4 10L4 14L8 14L17.4 4.6C18.2 3.8 18.2 2.5 17.4 1.8ZM7.2 12H6L6 10.8L14.8 2L16 3.2L7.2 12Z" fill="#706B69"/>
    </svg>

  );
};

export default EditIcon;
