import React, { useContext, useEffect, useState } from "react";
import { withApollo } from "react-apollo";
import { ItemDialogFormContext } from "./item-context/context";
import CustomList from "../../../components/shared/List";
import { LogBookSubEquipmentListMeta } from "../log-book-table/LogBookClusterTableMeta";
import LOGBOOK_LABEL from "../../../utils/constants/logbookLabel";
import { generateID } from "@digitallab/grid-common-components";
import { Module, formTypes } from "../../../constants";
import { OwcButton, OwcModalDialog } from "@one/react";
import { CoverSheetMainPageContext } from "../../cover-sheet/cover-sheet-main-page-context/context";
import { getEquipDetailLogList, getRunLogSubEquipments } from "../run-logs/run-logs-form/CreateRunLogChange";
import { getActionLogSubEquipments } from "../log-sheet/log-sheet-form/CreateLogSheetChange";
import { CircularProgress } from "@mui/material";
import { unionBy } from "lodash";

const ClusterItemSubComponentDialog = ({ client = {}, dataTestId = "ClusterItemSubComponentDialog" }) => {
  const { subComponentOpen, handleClose, rowParam } = useContext(ItemDialogFormContext);

  const { logType } = useContext(CoverSheetMainPageContext);
  const [subEquipmentData, setSubEquipmentData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const loadSelectedSubEquipment = async (equipmentData) => {
      if (logType === formTypes?.RUN_LOG) {
        return await getRunLogSubEquipments({
          client,
          id: equipmentData?.id
        });
      } else if (logType === formTypes?.ACTION_LOG) {
        return await getActionLogSubEquipments({
          client,
          id: equipmentData?.id
        });
      }
    };

    if (subComponentOpen) {
      setLoading(() => true);
      try {
        Promise.all([loadSelectedSubEquipment(rowParam)]).then(async (response) => {
          let promisses = [];
          if (response && response[0]?.length > 0) {
            let uniqRespose = unionBy(response[0], "inventoryId");
            uniqRespose.forEach((item) => {
              const logTypeKey = logType === formTypes?.RUN_LOG ? "runLogEntryId" : "logSheetEntryId";
              promisses.push(getEquipDetailLogList(item[logTypeKey], client, logType));
            });

            Promise.all(promisses).then((logDetails) => {
              logDetails.forEach((log) => {
                let info;
                if (logType === formTypes?.RUN_LOG) {
                  info = log.equipmentDetails?.addInfo;
                } else {
                  info = {
                    currentGxPStatusFromLogs: log.gxpReady?.value,
                    currentSystemStatusFromLogs: log.systemStatus?.value
                  };
                }
                const updatedLogStructure = {
                  ...log,
                  equipmentNickName: log.equipmentDetails?.equipmentNickName,
                  variant: log.equipmentDetails?.variant,
                  addInfo: info
                };
                setSubEquipmentData((prevData) => [...prevData, updatedLogStructure]);
              });
            });
          }
        });
      } catch (err) {
      } finally {
        setLoading(() => false);
      }
    }
    // eslint-disable-next-line
  }, [subComponentOpen]);

  return (
    <OwcModalDialog
      data-testid={`${dataTestId}-listing-subcomponent-dialog`}
      size="lg"
      visible={subComponentOpen}
      className="owcmodalZIndex"
      disableBackdropClick={true}
      onVisibleChange={(event) => {
        if (!event?.detail) {
          handleClose();
        }
      }}
    >
      <div slot="header" data-testid={`${dataTestId}-list-subequipment-list`}>
        <div data-testid={`${dataTestId}-list-subequipment-list`}>{LOGBOOK_LABEL.sub_equipment_list}</div>
      </div>
      {loading && (
        <>
          <div
            className="loading"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <CircularProgress size={30} />
          </div>
        </>
      )}

      {subComponentOpen && !loading && (
        <div data-testid={`${dataTestId}-subequipmentlist-dialog-content`}>
          <CustomList data={subEquipmentData ? subEquipmentData : []} meta={LogBookSubEquipmentListMeta} />
        </div>
      )}
      <div
        slot="actions"
        data-testid={`${dataTestId}-subequipmentlist-dialog-actions`}
        style={{ displa: "flex", justifyContent: "flex-end" }}
      >
        <OwcButton
          onClick={() => {
            setSubEquipmentData(() => []);
            handleClose();
          }}
          variant="primary"
          data-testid={`${dataTestId}-subequipmentlist-ok-button`}
          id={
            dataTestId === "run-log"
              ? generateID.buttonID(Module.BUTTON.run_log_popup.run_sub_component, "button")
              : generateID.buttonID(Module.BUTTON.action_log_popup.log_sub_component, "button")
          }
        >
          Ok
        </OwcButton>
      </div>
    </OwcModalDialog>
  );
};

export default withApollo(ClusterItemSubComponentDialog);
