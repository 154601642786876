import React, { useContext, useEffect, useState } from "react";
import { ExpandRow, Mainbox, Title, ThreeFourthsCell } from "../../log-book-item-form-dialog/FormsStyled";
import { RunStatusLabels } from "../../enums";
import { useSelector } from "react-redux";
import { omitOrReturn } from "../../../../utils/helpers/text";
import RunDateInput from "./run-data-inputs/RunDateInput";
import RunTimeInput from "./run-data-inputs/RunTimeInput";
import RunClassicInput from "./run-data-inputs/RunClassicInput";
import RunSelectWithOwnProp from "./run-data-inputs/RunSelectWithOwnProp";
import LOGBOOK_LABEL from "../../../../utils/constants/logbookLabel";
import COMMON_LOGS_DATA_MODEL from "../../../../utils/constants/commonLogsDataModel";
import RUN_LOGS_DATA_MODEL from "../../../../utils/constants/runLogsDataModel";
import { GridAutocomplete } from "@digitallab/grid-common-components";
import { useFormikContext } from "formik";
import styled from "styled-components";
import { uniq } from "underscore";
import { GridAutoCompleteStyles } from "../../../../components/shared/GridAutoCompleteStyles";
import { generateID } from "@digitallab/grid-common-components";
import { Module, entryType, formStates } from "../../../../constants";
import { Chip, TextField, createFilterOptions } from "@mui/material";
import { OwcChip, OwcTextarea } from "@one/react";
import CommonOwcEditableDropDown from "../../../../components/shared/CommonOwcEditableDropDown";
import { find } from "lodash";
import { CoverSheetMainPageContext } from "../../../cover-sheet/cover-sheet-main-page-context/context";
import { changeDateFormat } from "../../helpers";

const chip = {
  textOverflow: "ellipsis",
  overflow: "hidden",
  wordBreak: "break-all",
  whiteSpace: "wrap",
  maxWidth: "100px",
  border: "1px solid var(--one-color-gray-800)",
  borderRadius: 25
};

const Container = styled.div`
  & > div > div > .MuiInputBase-root {
    padding-top: 12px;
  }
`;
const MediaTypeContainer = styled.div`
  & > div > div > .MuiAutocomplete-inputFocused {
    padding: 7px 4px;
  }
  & > div > div > .MuiInputBase-root > span {
    color: #0066cc;
  }
`;

const RunDataInputsBox = ({ equipmentDetail }) => {
  const mediaTypes = useSelector((store) => store.runLogsForm.mediaTypes.map((mediaType) => omitOrReturn(mediaType)));
  const [inputValue, setInputValue] = useState("");
  const [renderTagValue, setRenderTagValue] = useState(false);
  const [runStartTimeObj, setRunStartTimeObj] = useState(null);
  const [runEndTimeObj, setRunEndTimeObj] = useState(null);
  const tips = useSelector((store) => store.runLogsForm.tips);
  const assays = useSelector((store) => store.runLogsForm.instrumentSuggestion?.installedTests);
  const { formState } = useContext(CoverSheetMainPageContext);
  const formik = useFormikContext();
  const [selectedRunStatus, setSelectedRunStatus] = useState(null);
  const [selectedMediaType, setSelectedMediaType] = useState(
    formState === formStates?.EDITABLE_FORM ? equipmentDetail?.mediaType : []
  );
  const gxpReadys = useSelector((store) => store.runLogsForm.gxpReadys);
  const systemStatuses = useSelector((store) => store.runLogsForm.systemStatuses);

  useEffect(() => {
    if (formik.values.runStatus) {
      setSelectedRunStatus(() => {
        const obj = find(RunStatusLabels, { key: formik.values.runStatus });
        return obj?.value || null;
      });
    } else {
      setSelectedRunStatus(() => null);
    }
  }, [formik.values.runStatus]);

  useEffect(() => {
    if (equipmentDetail.entryType === entryType.cluster) {
      formik.setFieldValue("gxpReady", formik.values.gxpReadyCluster);
      formik.setFieldValue("systemStatus", formik.values.systemStatusCluster);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [equipmentDetail.entryType, formik.values.gxpReadyCluster, formik.values.systemStatusCluster]);

  useEffect(() => {
    if (formState === formStates?.EDITABLE_FORM) {
      setSelectedMediaType(() => equipmentDetail?.mediaType);
    }
    if (equipmentDetail?.entryType === entryType?.cluster) {
      let gxpReady = equipmentDetail?.qualificationStatus;
      let systemStatus = equipmentDetail?.equipSystemStatus;
      formik.setFieldValue(
        "gxpReadyCluster",
        find(gxpReadys, {
          key: gxpReady
        }) || {
          key: "-",
          value: "-"
        }
      );
      formik.setFieldValue(
        "systemStatusCluster",
        find(systemStatuses, {
          value: systemStatus
        }) || {
          key: "-",
          value: "-"
        }
      );
    }
    // eslint-disable-next-line
  }, []);
  const filter = createFilterOptions();
  const handleOnFilterOptions = (options, params) => {
    const filtered = filter(options, params);

    // Suggest the creation of a new value
    if (params.inputValue !== "") {
      filtered.push({
        inputValue: params.inputValue,
        value: `Add "${params.inputValue}"`
      });
    }

    return filtered;
  };
  const onChangeItemToValue = (newValue, { propValue, propLabel }) => {
    const selectedMediaTypes = newValue.map((item) => {
      if (typeof item === "object" && item.inputValue) {
        item[propValue] = item.inputValue;
        item[propLabel] = item.inputValue.replace(/\s/g, "_") + new Date().toISOString();
        delete item["inputValue"];
        setInputValue("");
      }
      return item;
    });

    return uniq(selectedMediaTypes, (item) => item[propLabel].toLowerCase());
  };

  const renderTags = (selectedObjects, getTagProps) =>
    selectedObjects?.map((selectedObject, index) => {
      let chipLabel = selectedObject?.["value"];
      return (
        <>
          <Chip
            data-testid={`-chip-for-${chipLabel}`}
            key={selectedObject}
            variant="outlined"
            label={chipLabel}
            size="small"
            style={chip}
            {...getTagProps({ index })}
          />
        </>
      );
    });

  const handleOnRenderInput = (params) => (
    <TextField
      {...params}
      onChange={(e) => {
        setInputValue(e.target.value);
      }}
      label={RUN_LOGS_DATA_MODEL.mediaType.value}
      variant={"filled"}
      error={formik.errors["mediaType"]}
      helperText={formik.errors["mediaType"]}
      data-testid={"run-data-media-type-text-field"}
      required={false}
      InputProps={{
        ...params.InputProps,
        endAdornment: <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>
      }}
      size="small"
    />
  );

  const onSelectedRunStatus = (runStatus) => {
    if (formik.values.runStatus !== runStatus?.key) {
      formik.setValues({
        ...formik.values,
        runStatus: runStatus?.key
      });
      setSelectedRunStatus(() => runStatus?.value ?? null);
    }
  };

  useEffect(() => {
    const tempStartTime = formik.values[RUN_LOGS_DATA_MODEL.runStartTime.key]
      ? new Date(changeDateFormat(new Date()) + " " + formik.values[RUN_LOGS_DATA_MODEL.runStartTime.key])
      : null;
    const tempEndTime = formik.values[RUN_LOGS_DATA_MODEL.runEndTime.key]
      ? new Date(changeDateFormat(new Date()) + " " + formik.values[RUN_LOGS_DATA_MODEL.runEndTime.key])
      : null;
    setRunStartTimeObj(tempStartTime);
    setRunEndTimeObj(tempEndTime);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik?.values]);

  return (
    <Mainbox>
      <Title data-testid="run-data-title">{LOGBOOK_LABEL.run_data}</Title>
      <RunDateInput
        property="runStartDate"
        otherDateProperty="runEndDate"
        label={COMMON_LOGS_DATA_MODEL.runStartDate.value + ` *`}
        testid="run-start-date"
        required={true}
      />
      <RunTimeInput
        property="runStartTime"
        label={RUN_LOGS_DATA_MODEL.runStartTime.value}
        timeObj={runStartTimeObj}
        setTimeObj={setRunStartTimeObj}
      />
      <RunDateInput
        property="runEndDate"
        otherDateProperty="runStartDate"
        label={COMMON_LOGS_DATA_MODEL.runEndDate.value + ` *`}
        testid="run-end-date"
        required={true}
      />
      <RunTimeInput
        property="runEndTime"
        label={RUN_LOGS_DATA_MODEL.runEndTime.value}
        timeObj={runEndTimeObj}
        setTimeObj={setRunEndTimeObj}
      />
      <Container>
        <CommonOwcEditableDropDown
          labelValue={false}
          label={RUN_LOGS_DATA_MODEL.runStatus.value + ` *`}
          defaultLabel={RUN_LOGS_DATA_MODEL.runStatus.value + ` *`}
          propValue="value"
          list={RunStatusLabels}
          selected={selectedRunStatus}
          onChange={(selectedValue) => {
            const selVal = RunStatusLabels?.find((value, index) => index === selectedValue);
            onSelectedRunStatus(selVal ?? null);
          }}
          required={true}
          helperText={formik.errors[RUN_LOGS_DATA_MODEL.runStatus.key]}
        />
      </Container>
      <RunClassicInput
        property="runIdentification"
        label={RUN_LOGS_DATA_MODEL.runIdentification.value}
        testid="run-identification"
        fileTestId="run-data-inputs"
      />
      <RunClassicInput
        property="samplesProcessed"
        label={RUN_LOGS_DATA_MODEL.samplesProcessed.value}
        testid="samples-processed"
        fileTestId="run-data-inputs"
      />
      <RunClassicInput
        property="numberOfRuns"
        label={RUN_LOGS_DATA_MODEL.numberOfRuns.value}
        testid="number-of-runs"
        fileTestId="run-data-inputs"
      />
      <ThreeFourthsCell>
        <OwcTextarea
          testid="description"
          fileTestId="run-data-inputs"
          cols={20}
          id={generateID.dropDownID(Module.POPUP_FIELDS.description, "textbox")}
          label={RUN_LOGS_DATA_MODEL.description.value + ` *`}
          onInputChange={(e) => {
            formik.setFieldValue("description", e.target.value, true);
          }}
          onInputKeyDown={(e) => {
            formik.setFieldValue("description", e.target.value, true);
          }}
          onInputReset={() => formik.setFieldValue("description", "", true)}
          maxLength={200}
          minLength={0}
          resizable
          rows={2}
          reset
          value={formik.values.description}
        />
      </ThreeFourthsCell>
      <RunClassicInput
        property="defectId"
        label={RUN_LOGS_DATA_MODEL.defectId.value}
        testid="defect-id"
        fileTestId="run-data-inputs"
      />
      <RunClassicInput
        property="eLNid"
        label={RUN_LOGS_DATA_MODEL.eLNid.value}
        testid="eln-id"
        fileTestId="run-data-inputs"
      />
      <MediaTypeContainer>
        <GridAutocomplete
          dataTestid="run-data-media-type"
          fullWidth
          multiple
          sx={GridAutoCompleteStyles?.gridAutoCompleteLableShrink}
          id={generateID.dropDownID(Module.POPUP_FIELDS.mediaType, "dropdown")}
          label={RUN_LOGS_DATA_MODEL.mediaType.value}
          renderTagsAsChips={renderTagValue}
          renderTags={
            renderTagValue
              ? (tagValue, getTagProps) =>
                  tagValue.map((option, index) => (
                    <OwcChip variant="outlined" size="small" style={chip} {...getTagProps({ index })}>
                      {option["value"] ?? option}
                    </OwcChip>
                  ))
              : renderTags
          }
          onAutoCompleteChange={(selectedItem) => {
            selectedItem = onChangeItemToValue(selectedItem, {
              propValue: "value",
              propLabel: "key"
            });
            setSelectedMediaType(() => selectedItem);
            formik.setFieldValue("mediaType", selectedItem);
          }}
          optionsList={mediaTypes}
          propValue="value"
          propLabel="key"
          value={selectedMediaType}
          error={formik.errors["mediaType"]}
          helperText={formik.errors["mediaType"]}
          filterOptions={handleOnFilterOptions}
          showCheckBox={false}
          inputValue={inputValue}
          renderInput={handleOnRenderInput}
          onClose={() => setRenderTagValue(false)}
          onOpen={() => setRenderTagValue(true)}
          limitTags={1}
        />
      </MediaTypeContainer>

      <RunSelectWithOwnProp
        options={tips.map(({ key, value }) => ({ key, value }))}
        objectAsValue
        property={RUN_LOGS_DATA_MODEL.tipsUsed.key}
        Selectable={RUN_LOGS_DATA_MODEL.tipsUsed.value}
        testidPrefix="run-data"
        testid="tips-used"
        propValue="key"
        propLabel="value"
      />
      <RunClassicInput
        property="operatorUserName"
        label="Operator ID"
        testid="operator-user-name"
        fileTestId="run-data-inputs"
      />

      <ExpandRow>
        <RunSelectWithOwnProp
          options={assays ?? []}
          property={RUN_LOGS_DATA_MODEL.assay.key}
          Selectable={RUN_LOGS_DATA_MODEL.assay.value}
          testidPrefix="run-data"
          testid="assay"
          propValue="name"
          propLabel="name"
          multiple
          fullWidth
        />
      </ExpandRow>
    </Mainbox>
  );
};

export default RunDataInputsBox;
