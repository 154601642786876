import { changeDateFormat } from "../features/log-book/helpers";
import { DigitalLabLogbookInstrumentModelType } from "../models/DigitalLabLogbookInstrumentModelType";
import { omitOrReturn } from "../utils/helpers/text";
import { IActionLogFormValues, IRunLogFormValues, ISelectValues } from "./StepperContainer";
import gql from "graphql-tag";
import { v4 } from "uuid";

type ExternalData = {
  user: { site: string; user: string; name: string };
  systemStatuses: ISelectValues[];
  gxpReadys: ISelectValues[];
};

export const createRunLogBulkVariables = (
  formValues: IRunLogFormValues,
  equipmentList: Partial<DigitalLabLogbookInstrumentModelType>[],
  externalData: ExternalData
) => {
  const { user, systemStatuses, gxpReadys } = externalData;
  const variables = {
    ...formValues,
    runStartDate:
      formValues.runStartDate instanceof Date ? changeDateFormat(formValues.runStartDate) : formValues.runStartDate!,
    runEndDate:
      formValues.runEndDate instanceof Date ? changeDateFormat(formValues.runEndDate) : formValues.runEndDate!,
    site: user.site ?? "",
    approverId: user.user ?? "",
    approverUserName: user.name ?? ""
  };

  return Object.fromEntries(
    equipmentList.map((equipment, index) => {
      const systemStatus = (systemStatuses as ISelectValues[]).find(
        (item) => equipment.systemStatus?.toLowerCase() === item.value.toLowerCase()
      );
      return [
        `input${index}`,
        {
          // KEEP ...VARIABLES AT THE TOP OR IT WILL OVERWRITE OTHER VALUES
          ...variables,
          runLogEntryId: v4(),
          inventoryId: equipment.inventoryId ?? "",
          equipmentModel: equipment.equipmentModel ?? "",
          serialNumber: equipment.serialNumber ?? "",
          equipmentId: equipment.equipmentId ?? "",
          modelVersion: equipment.modelVersion ?? "",

          systemStatus: {
            key: systemStatus?.key ?? equipment.systemStatus ?? "",
            value: systemStatus?.value ?? equipment.systemStatus ?? ""
          },
          gxpReady: omitOrReturn(
            gxpReadys.find(
              (entry: { key: string | undefined }) =>
                entry.key?.toLowerCase() === equipment.qualificationStatus?.toLowerCase()
            )
          ) || { key: "", value: "" }
        }
      ];
    })
  );
};

export const createRunLogBulkMutation = (equipmentList: Partial<DigitalLabLogbookInstrumentModelType>[]) => {
  const mutationString = equipmentList
    .map(
      (_, index) => `
    runLog${index}: createDigitalLabLogbookRunLogChange(input: $input${index}) {
    id
    }
  `
    )
    .join("\n");

  const output = ` mutation BulkRunLogMutations(
        ${equipmentList.map((_, index) => `$input${index}: CreateDigitalLabLogbookRunLogChangeInput!`).join(", ")}
      ) {
        ${mutationString}
      }`;
  return gql(output);
};
export const createActionLogBulkVariables = (
  formValues: IActionLogFormValues,
  equipmentList: Partial<DigitalLabLogbookInstrumentModelType>[],
  externalData: ExternalData
) => {
  const { user } = externalData;
  const { action, ...otherFormValues } = formValues;
  const variables = {
    ...otherFormValues,
    actionDate: formValues.actionDate instanceof Date ?
      changeDateFormat(formValues.actionDate) :
      formValues.actionDate!,
    approverId: user.user ?? "",
    inventoryIds: equipmentList.map((element) => element.inventoryId),
    action: { key: action, value: action },
  };
  return {
    input: {
      ...variables
    }
  };
};
