import { get, omit } from "underscore";
import { API } from "aws-amplify";
import { configureAwsAppSync } from "./configureAwsAppSync";
import { formTypes } from "../../constants";
import { find } from "lodash";

const createOrFilter = (arrayFilterKeys, arrayFilters) => {
  return arrayFilterKeys
    ?.reduce(
      (acc, key) =>
        arrayFilters[key].length
          ? [
              ...acc,
              arrayFilters[key]?.reduce(
                (acc_i, val) => (typeof val === "string" ? [...acc_i, getGQLFilterObj({ [key]: val })] : acc_i),
                []
              )
            ]
          : acc,
      []
    )
    .filter((arr) => arr.length);
};

export const createGQLFilterObj = (obj) => {
  if (typeof obj !== "object" || obj === null || Array.isArray(obj)) {
    return null;
  }
  const objKeys = Object.keys(obj);
  if (!objKeys.length) {
    return null;
  }
  const arrayFilters = objKeys.reduce(
    (acc, curr) => (Array.isArray(obj[curr]) ? { ...acc, [curr]: obj[curr] } : acc),
    {}
  );
  const arrayFilterKeys = Object.keys(arrayFilters);
  if (arrayFilterKeys.length) {
    const OrFilter = createOrFilter(arrayFilterKeys, arrayFilters);
    if (!OrFilter.length) {
      return getGQLFilterObj(omit(obj, arrayFilterKeys));
    }
    const simpleFilter = getGQLFilterObj(omit(obj, arrayFilterKeys));
    if (!simpleFilter) {
      return {
        and: [...OrFilter?.map((arr) => ({ or: arr }))]
      };
    }
    return {
      and: [...OrFilter?.map((arr) => ({ or: arr })), simpleFilter]
    };
  }
  return getGQLFilterObj(obj);
};

export const getGQLFilterObj = (obj) => {
  if (typeof obj !== "object" || obj === null || Array.isArray(obj)) {
    return null;
  }
  const result = Object.keys(obj).reduce((acc, cur) => {
    if (obj?.[cur] === null || obj?.[cur] === undefined) {
      return acc;
    }
    if (typeof obj?.[cur] === "string" && (obj?.[cur]?.trim() === "" || obj?.[cur]?.trim() === "all")) {
      return acc;
    }
    return { ...acc, [cur]: { eq: obj[cur] } };
  }, {});
  return Object.keys(result).length === 0 ? null : result;
};

const fetchOne = async ({
  client,
  query,
  fetchPolicy,
  variables,
  nextToken,
  dataPath,
  items,
  drillData,
  limit,
  awsAppSyncConfig
}) => {
  let result = [];
  if (awsAppSyncConfig) {
    configureAwsAppSync(awsAppSyncConfig);
    result = await API.graphql({
      client,
      query,
      variables: {
        ...variables,
        nextToken
      }
    });
  } else {
    try {
      result = await client.query({
        query,
        fetchPolicy,
        variables: {
          ...variables,
          nextToken
        }
      });
    } catch (e) {
      console.error("error", e.message);
      // bad error handling, keeping the flow intact, + printing error for CloudWatch
      throw e;
    }
  }

  const page = get(result, [...dataPath, "items"]) ?? [];
  items = Array.isArray(page) ? [...items, ...page] : items;
  const _nextToken = get(result, [...dataPath, "nextToken"]);
  nextToken = typeof _nextToken === "string" && _nextToken ? _nextToken : null;
  const shouldKeepFetching = drillData ? drillData : items.length < limit;
  return { nextToken, items, shouldKeepFetching };
};

export const getAllData = async ({
  client,
  query,
  fetchPolicy = "network-only",
  variables = {},
  dataPath,
  drillData = false,
  awsAppSyncConfig = null
}) => {
  const emptyResponse = {
    items: [],
    nextToken: null,
    error: null
  };
  if (!client || !query || !variables) {
    return emptyResponse;
  }
  if (!Array.isArray(dataPath)) {
    return emptyResponse;
  }
  if (dataPath.length === 0) {
    return emptyResponse;
  }
  try {
    let items = [];
    let nextToken = variables?.nextToken ?? null;
    let shouldKeepFetching = true;
    const { limit = 10 } = variables;
    do {
      ({ nextToken, items, shouldKeepFetching } = await fetchOne({
        client,
        query,
        fetchPolicy,
        variables,
        nextToken,
        dataPath,
        items,
        shouldKeepFetching,
        drillData,
        limit,
        awsAppSyncConfig
      }));
    } while (nextToken !== null && shouldKeepFetching);
    return {
      items,
      nextToken,
      error: null
    };
  } catch (err) {
    return { ...emptyResponse, error: err };
  }
};

const TIMEOUT_SUBSCRIBE_AFTER = 30000;

export const waitUntilSubscribeCondition = async (client, subDoc, condition, timeout = TIMEOUT_SUBSCRIBE_AFTER) => {
  return new Promise((res, rej) => {
    let timeoutId = null;
    const cancelTimeout = () => {
      clearTimeout(timeoutId);
    };

    const sub = client
      .subscribe({
        query: subDoc,
        fetchPolicy: "no-cache"
      })
      .subscribe({
        next: ({ data }) => {
          if (condition(data)) {
            sub.unsubscribe();
            cancelTimeout();
            res();
          }
        },
        error: (error) => {
          sub.unsubscribe();
          cancelTimeout();
          rej(error);
        }
      });

    timeoutId = setTimeout(() => {
      rej();
      sub.unsubscribe();
    }, timeout);
  });
};

export const waitUntilSubscribeConditionTimes = async (
  client,
  subDoc,
  condition,
  times = 1,
  timeout = TIMEOUT_SUBSCRIBE_AFTER
) => {
  for (let i = 0; i < times; i++) {
    try {
      return await waitUntilSubscribeCondition(client, subDoc, condition, timeout);
    } catch (e) {
      if (i + 1 === times) {
        throw e;
      }
    }
  }
};
/**
 * getContainerHeight - Get the dynamic value of containter height
 * @param {string} currentEnv - If env banner is open
 * @param {number} remainingHeight - height of remaining elements in page
 * @return {any} container height : windowSize - remainingHeight
 */
export const getContainerHeight = (currentEnv, remainingHeight) => {
  return currentEnv
    ? window.innerHeight - (126 + remainingHeight) // envBanner + header + footer + pageHeader
    : window.innerHeight - (75 + remainingHeight); //  header + footer + pageHeader
};
export const getContainerHeightPercentage = (currentEnv) => {
  return ((window.innerHeight - (currentEnv ? 118 : 75)) / window.innerHeight) * 100;
};

export const getPopoverHeightPx = (currentEnv) => {
  return window.innerHeight - (currentEnv ? 360 : 310);
};

export const getSubEquipments = async ({
  promises,
  selectedEquipment,
  equipmentData,
  logType,
  gxpReadys,
  systemStatuses,
  isEditMode,
  subEquipmentLogDetails = null
}) => {
  let selectedSubEquip = [];
  let clusterSubEquipments = [];
  const allResults = Promise.all([...promises]);
  return allResults
    ?.then((subEquipments) => {
      subEquipments?.forEach((subEquipment) => {
        let tempObj = { ...subEquipment };
        const qsObj = find(gxpReadys, {
          key: tempObj?.qualificationStatus
        });
        const ssObj = find(systemStatuses, {
          value: tempObj?.systemStatus
        });
        tempObj.qualificationStatus = qsObj?.value || null;
        tempObj.equipSystemStatus = ssObj?.value || null;
        if (isEditMode) {
          const logTypeKey = logType === formTypes?.RUN_LOG ? "runLogEntryId" : "logSheetEntryId";
          tempObj[logTypeKey] = null;
          const obj = find(selectedEquipment[0], {
            inventoryId: tempObj?.inventoryId
          });
          const logObj = find(subEquipmentLogDetails, {
            inventoryId: tempObj?.inventoryId
          });
          tempObj.gxpReady = logObj ? logObj?.gxpReady : null;
          tempObj.systemStatus = logObj ? logObj?.systemStatus : null;

          if (obj) {
            tempObj[logTypeKey] = obj[logTypeKey];
            selectedSubEquip.push(tempObj);
          }
        } else {
          tempObj.gxpReady = qsObj || null;
          tempObj.systemStatus = ssObj || null;
        }

        if (equipmentData?.inventoryId === tempObj?.clusterId) {
          clusterSubEquipments.push(tempObj);
        } else if (
          find(clusterSubEquipments, {
            inventoryId: tempObj?.clusterId
          })
        ) {
          const updatedClusterSubEquipment = clusterSubEquipments?.map((subEquip) => {
            if (subEquip?.inventoryId === tempObj?.clusterId) {
              subEquip.subEquipment = subEquip.subEquipment ? [...subEquip.subEquipment, tempObj] : [tempObj];
              return subEquip;
            }

            return subEquip;
          });
          clusterSubEquipments = [...updatedClusterSubEquipment];
        }
      });
      equipmentData.subEquipment = [...clusterSubEquipments];
      return { equipmentData, selectedSubEquip };
    })
    .catch(() => {
      return null;
    });
};
