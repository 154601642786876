import { EN_DASH, THEME } from "@digitallab/grid-common-components";
import { OwcChip, OwcIconButton } from "@one/react";
import { DigitalLabLogbookInstrumentModelType } from "../models/DigitalLabLogbookInstrumentModelType";
import { equipmentStatus, GXPemptyInstrumentsOptions, statusChip } from "../constants";

const defaultCellParams = {
  valueFormatter: ({ value }: { value: unknown }) => {
    return value
      ? typeof value === "string"
        ? value
        : Array.isArray(value)
        ? value.join(", ")
        : JSON.stringify(value)
      : EN_DASH;
  },
  tooltipValueGetter: ({ value }: { value?: unknown }) => {
    return value
      ? typeof value === "string"
        ? value
        : Array.isArray(value)
          ? value.join(", ")
          : JSON.stringify(value)
      : undefined
  },
};

export const COLUMNS_DEFINITIONS = (handleDelete: (data: { data: DigitalLabLogbookInstrumentModelType }) => void) => [
  {
    ...defaultCellParams,
    field: "equipmentModel",
    key: "equipmentModel",
    headerName: "Model",
  },
  {
    ...defaultCellParams,
    field: "serialNumber",
    key: "serialNumber",
    headerName: "Serial number"
  },
  {
    ...defaultCellParams,
    field: "equipmentId",
    key: "equipmentId",
    headerName: "Equipment ID"
  },
  {
    ...defaultCellParams,
    field: "equipmentNickName",
    key: "equipmentNickName",
    headerName: "Nickname"
  },
  {
    ...defaultCellParams,
    field: "belongingToGroup",
    key: "belongingToGroup",
    headerName: "Group "
  },
  {
    ...defaultCellParams,
    field: "location",
    key: "location",
    headerName: "Location"
  },
  {
    ...defaultCellParams,
    field: "qualificationStatus",
    key: "qualificationStatus",
    headerName: "Qualification status",
    cellRenderer: ({ value }: { value: string }) => {
      if (GXPemptyInstrumentsOptions.qualificationStatus.map(({ key }) => key).includes(value))
        value = GXPemptyInstrumentsOptions.qualificationStatus.find((item) => item.key === value)?.value || "";
      return (
        <div
          style={{
            height: "100%",
            display: "flex",
            alignItems: "center"
          }}
        >
          {value ? (
            <OwcChip
              outlined={true}
            >
              <strong>{value}</strong>
            </OwcChip>
          ) : (
            EN_DASH
          )}
        </div>
      );
    }
  },
  {
    ...defaultCellParams,
    field: "status",
    key: "systemStatus",
    headerName: "System status",
    cellRenderer: ({ value }: { value: string }) => {
      const status = statusChip[value as keyof typeof statusChip];
      return (
        <div
          style={{
            height: "100%",
            display: "flex",
            alignItems: "center"
          }}
        >
          {status ? (
            <OwcChip
              outlined={true}
              style={{
                color:
                  status === equipmentStatus.deleted.value
                    ? THEME["one-color-red-a-300"]
                    : status === equipmentStatus.pending.value
                      ? THEME["one-color-yellow-a-300"]
                      : THEME["one-color-cobas-green-600"]
              }}
            >
              <strong>{status}</strong>
            </OwcChip>
          ) : (
            EN_DASH
          )}
        </div>
      );
    }
  },
  {
    field: "actions",
    key: "actions",
    headerName: "",
    maxWidth: 60,
    filter: false,
    pinned: "right" as "right",
    lockVisible: true,
    cellRenderer: (data: { data: DigitalLabLogbookInstrumentModelType }) => (
      <OwcIconButton flat icon="delete" type="outlined" onClick={() => handleDelete(data)} />
    )
  }
];
