const initialState = {
  mediaTypes: [],
  tips: [],
  gxpReadys: [],
  systemStatuses: [],
  actionLog: [],
  instrumentSuggestions: [],
  instrumentSuggestion: null,
  megaClusterIds: []
};

export default initialState;
